import './scss/main.scss';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import Header from './app/header/Header';
import Footer from './app/footer/Footer';
import MainNav from './app/main-nav/MainNav';
import HomePage from './app/home-page/HomePage';
import LayoutSection from './app/layout-section/LayoutSection';
import Components from './app/components/Components';
import Emails from './app/emails/Emails';

function App() {
  return (
    <>
      <Header />
      <div className='container-fluid content-container ps-lg-4 pe-lg-5'>
        <div className='row'>
          <nav className='col-12 col-lg-auto offset-xxl-1 offset-xxxl-2'>
            <MainNav />
          </nav>
          <section className='col-12 col-lg col-xxl-8 col-xxxl-7 px-sm-4 px-lg-3 pb-5'>
            <Router>
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/layout/*" element={<LayoutSection />} />
                <Route path="/components/*" element={<Components />} />
                <Route path="/emails/*" element={<Emails />} />
              </Routes>
            </Router>
          </section>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default App;