import './_content-div.scss';
import React from 'react';
import { CopyBlock, monoBlue } from "react-code-blocks";

function ContentDiv() {
  return (
    <>
      <h1>Content Containers</h1>
      <p className='mb-6'>The way you lay out your content is entirely up to you and what the story ticket calls for, but here are a few helpful items that can help you with grouping content.</p>
      <h2>Rounded-Corner Content Div</h2>
      <p>As we move the dashboard into the realm of Software as a Service (SaaS), this rounded corner content div is a handy means to  encapsulate sections of content to make them more legible to users.</p>
      <div className='container-fluid mb-4'>
        <div className='row'>
          <div className='col rounded-corner-div pt-3'>
            <div className='row mb-2'>
              <div className='col-12 col-md-6 col-xl-3'>
                <p className='big-number-label'>Big Example Number 1</p>
                <p className='big-number'><sup>$</sup>123,456.78</p>
              </div>
              <div className='col-12 col-md-6 col-xl-3'>
                <p className='big-number-label'>Big Example Number 2</p>
                <p className='big-number'><sup>$</sup>123,456.78</p>
              </div>
              <div className='col-12 col-md-6 col-xl-3'>
                <p className='big-number-label'>Big Example Number 3</p>
                <p className='big-number'><sup>$</sup>123,456.78</p>
              </div>
              <div className='col-12 col-md-6 col-xl-3'>
                <p className='big-number-label'>Big Example Number 4</p>
                <p className='big-number'><sup>$</sup>123,456.78</p>
              </div>
            </div>
            <div className='row'>
              <div className='col bottom-rounded-corners light-gray-bg pt-3'>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus euismod nisl vitae viverra imperdiet.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p>The setup is merely placing the <span className='magenta-text'>.rounded-corner-div</span> on the containing div. It is suggested you also use the Bootstrap class <span className='magenta-text'>.pt-3</span> on
        the div as well to add padding up top and even out the spacing around the inside. We do not include this to give you flexibility in how you lay out your content.</p>
      <p>If you wish to use background colors on parts of your internal content, we have also included the classes <span className='magenta-text'>.top-rounded-corners</span> and <span className='magenta-text'>.bottom-rounded-corners</span> that will add the appropriate border-radius to your div and make your background color flush with the edges.</p>
      <div className='copy-block-div mb-5'>
        <p className='semibold-text mb-0'>Code</p>
        <CopyBlock
          language="html"
          text={`<div class="row">
    <div class="col rounded-corner-div pt-3">
        ...
    </div>
</div>

<div class="row">
    <div class="col rounded-corner-div pt-3">
      <div class="row mb-2">
        <div class="col">
          Upper Content
        </div>
        <div class="col bottom-rounded-corners light-gray-bg pt-3">
          Lower Content
        </div>
      </div>
    </div>
</div>`}
          theme={monoBlue}
          showLineNumbers={false}
          codeBlock
        />
      </div>
      <p>You can use these divs in all sorts of ways to break down content into easy to read containers. It will require though some clever use of Bootstrap grid and spacing classes.</p>
      <div className='container-fluid mb-6'>
        <div className='row'>
          <div className='col rounded-corner-div pt-3 mb-3'>
            <div className='row'>
              <div className='col-12 col-md-6 col-xl-3'>
                <p className='font-size-075 mb-0'>Random Info Item 1</p>
                <p className='font-size-1 semibold-text'>Lorem Ipsum</p>
              </div>
              <div className='col-12 col-md-6 col-xl-3'>
                <p className='font-size-075 mb-0'>Random Info Item 2</p>
                <p className='font-size-1 semibold-text'>Dolor Sit Amet</p>
              </div>
              <div className='col-12 col-md-6 col-xl-3'>
                <p className='font-size-075 mb-0'>Random Info Item 3</p>
                <p className='font-size-1 semibold-text'>Consectetur Adipiscing</p>
              </div>
              <div className='col-12 col-md-6 col-xl-3'>
                <p className='font-size-075 mb-0'>Random Info Item 4</p>
                <p className='font-size-1 semibold-text'>Vivamus Sed Purus</p>
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-12 col-lg-4 pe-lg-4'>
            <div className='row d-flex align-items-stretch'>
              <div className='col-12 rounded-corner-div pt-3 mb-3'>
                <div className='row'>
                  <div className='col-12'>
                    <p className='big-number-label'>Big Example Number 1</p>
                    <p className='big-number'><sup>$</sup>123,456.78</p>
                  </div>
                  <div className='col-12'>
                    <p className='big-number-label'>Big Example Number 2</p>
                    <p className='big-number'><sup>$</sup>123,456.78</p>
                  </div>
                  <div className='col-12'>
                    <p className='big-number-label'>Big Example Number 3</p>
                    <p className='big-number'><sup>$</sup>123,456.78</p>
                  </div>
                  <div className='col-12'>
                    <p className='big-number-label'>Big Example Number 4</p>
                    <p className='big-number'><sup>$</sup>123,456.78</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-12 col-lg-8 ps-lg-4'>
            <div className='row'>
              <div className='col rounded-corner-div pt-3 mb-3'>
                <table className="table table-bordered table-no-outer-border mb-lg-4">
                  <thead>
                    <tr>
                      <th scope="col" className='text-start'>Broker</th>
                      <th scope="col">State</th>
                      <th scope="col">Forecasted</th>
                      <th scope="col">Actuals</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td scope="row" className='text-start'>Michael Rogers</td>
                      <td>Illinois</td>
                      <td>$1,234.56</td>
                      <td>$1,123.45</td>
                    </tr>
                    <tr>
                      <td scope="row" className='text-start'>Carlos Miller</td>
                      <td>Indiana</td>
                      <td>$789.01</td>
                      <td>$778.90</td>
                    </tr>
                    <tr>
                      <td scope="row" className='text-start'>Nicole Edwards</td>
                      <td>Texas</td>
                      <td>$2,345.67</td>
                      <td>$2,123.45</td>
                    </tr>
                    <tr>
                      <td scope="row" className='text-start'>Steven Davis</td>
                      <td>New York</td>
                      <td>$1,567.89</td>
                      <td>$1,564.56</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <h2>Form Div</h2>
      <p>Similar to the rounded corner content div, you can use the class <span className='magenta-text'>.form-div</span> to build containment on groups of form fields.
        Using <span className='magenta-text'>.form-divider</span> on <span className='magenta-text'>&lt;hr&gt;</span> tags can also style simple divisions within one of these containers.</p>
      <div className='container-fluid mb-4'>
        <div className='row'>
          <div className='col form-div'>
            <div className='row'>
              <div className='col-12'>
                <h3 className='add-underline mb-3'>Form Section 1</h3>
              </div>
            </div>
            <div className='row'>
              <div className='col-12 col-md-4'>
                <div className='form-element-container'>
                  <label htmlFor='textBox01'>Text Box 1</label>
                  <input
                    type="text"
                    className='form-control'
                    id="textBox01"
                  />
                </div>
              </div>
              <div className='col-12 col-md-4'>
                <div className='form-element-container'>
                  <label htmlFor='textBox02'>Text Box 2</label>
                  <input
                    type="text"
                    className='form-control'
                    id="textBox02"
                  />
                </div>
              </div>
              <div className='col-12 col-md-4'>
                <div className='form-element-container'>
                  <label htmlFor='textBox03'>Text Box 3</label>
                  <input
                    type="text"
                    className='form-control'
                    id="textBox03"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col form-div'>
            <div className='row'>
              <div className='col-12'>
                <h3 className='add-underline mb-3'>Form Section 2</h3>
              </div>
            </div>
            <div className='row'>
              <div className='col-12 col-md-4'>
                <div className='form-element-container'>
                  <label htmlFor='textBox04'>Text Box 1</label>
                  <input
                    type="text"
                    className='form-control'
                    id="textBox04"
                  />
                </div>
              </div>
              <div className='col-12 col-md-4'>
                <div className='form-element-container'>
                  <label htmlFor='textBox05'>Text Box 2</label>
                  <input
                    type="text"
                    className='form-control'
                    id="textBox05"
                  />
                </div>
              </div>
              <div className='col-12 col-md-4'>
                <div className='form-element-container'>
                  <label htmlFor='textBox06'>Text Box 3</label>
                  <input
                    type="text"
                    className='form-control'
                    id="textBox06"
                  />
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <hr className='form-divider' />
              </div>
            </div>
            <div className='row'>
              <div className='col-12 col-md-6'>
                <div className="form-element-container">
                  <label htmlFor="textArea01">Text Area 1</label>
                  <textarea
                    className="form-control"
                    id="textArea01"
                    rows={6}
                    defaultValue={""}
                  />
                </div>
              </div>
              <div className='col-12 col-md-6'>
                <div className="form-element-container">
                  <label htmlFor="textArea02">Text Area 2</label>
                  <textarea
                    className="form-control"
                    id="textArea02"
                    rows={6}
                    defaultValue={""}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='copy-block-div mb-6'>
        <p className='semibold-text mb-0'>Code</p>
        <CopyBlock
          language="html"
          text={`<div class="row">
    <div class="col form-div">
        ...
        <hr class="form-divider">
        ...
    </div>
</div>`}
          theme={monoBlue}
          showLineNumbers={false}
          codeBlock
        />
      </div>

      <h2>Top Form</h2>
      <p>The top form is an ideal component for form-driven pages where you need a general form to set up the content of the page. This is used in rates pages and many reports. It&rsquo;s mostly meant to sit at the top of the page as the &ldquo;starting point&rdquo; for the user. While it could be used mid-page, it&rsquo;s not suggested.</p>

      <div className='container-fluid mb-4 px-0'>
        <div className='row top-form'>
          <div className='col form-container'>
            <div className='row main-form-row'>

            <div className='col form-element-container'>
              <label for='textBoxExample'>Text Box Example</label>
              <input type='text' className='form-control' id='textBoxExample' placeholder='' />
            </div>

            <div className='col form-element-container'>
              <label for='datepickerMockupExample'>Datepicker Mockup Example</label>
              <div className='input-group'>
                <input type='text' className='form-control' id='datepickerMockupExample' placeholder='MM/DD/YYYY' aria-label='Datepicker Mockup Example' aria-describedby='button-addon' />
                <button className='btn btn-default' type='button' id='button-addon03'>
                <i className='fa-solid fa-calendar-days' aria-hidden='true'></i></button>
              </div>
            </div>

            <div className='col form-element-container'>
                <label for='formDropdownExample'>Form Dropdown Example</label>
                <div className='dropdown flex-fill pseudo-form-control'>
                    <a className='dropdown-toggle' href='javascript:void(0);' role='button' id='formDropdownExample' data-bs-toggle='dropdown' aria-expanded='false'>Please Select</a>
                    <div className='dropdown-menu' aria-labelledby='formDropdownExample' x-placement='bottom-start'>
                        <a className='dropdown-item' href='javascript:void(0);'>Choice One</a>
                        <a className='dropdown-item' href='javascript:void(0);'>Choice Two</a>
                        <a className='dropdown-item' href='javascript:void(0);'>Choice Three</a>
                    </div>
                </div>
            </div>

            </div>
            <div className='row'>
              <div className='col d-flex justify-content-center shifted-button'>
                <button type='button' className='btn btn-primary'>Primary</button>
              </div>
            </div>
          </div>
        </div>
      </div>


      <p>You set up the container with the <span className='magenta-text'>.top-form</span> class on the row div, which styles the container. You then use <span className='magenta-text'>.form-container</span> on the column div and <span className='magenta-text'>.main-row-div</span> on the containment of the form elements to bring it all together.</p>
      <p className='mb-4'>Inside you can set up your form almost any way you like. For the submit button, you use a combination of flex classes found in Bootstrap, and add the <span className='magenta-text'>.shifted-button</span> class to the containment div holding the button, not the button itself. This will position it on the border as shown.</p>
      <div className='copy-block-div mb-5'>
        <p className='semibold-text mb-0'>Code</p>
        <CopyBlock
          language="html"
          text={`<div class="row top-form">
  <div class="col form-container">
    <div class="row main-form-row">
      ...
    </div>
    <div class="row">
      <div class="col d-flex justify-content-center shifted-button">
        <button type="button" class="btn btn-primary">Primary</button>
      </div>
    </div>
  </div>
</div>`}
          theme={monoBlue}
          showLineNumbers={false}
          codeBlock
        />
      </div>

      <p className='mb-4'>If you need to display two buttons, use the <span className='magenta-text'>.shifted-button-div</span> class on the containment div instead of <span className='magenta-text'>.shifted-button</span>, and wrap your buttons in another div inside, using the <span className='magenta-text'>.shifted-div</span> class. This will position them both nicely.  Please make sure not to use <span className='magenta-text'>.btn-primary</span> on both buttons, but instead <span className='magenta-text'>.btn-secondary</span> or <span className='magenta-text'>.btn-light</span>. There should be one designated button we want users to click the most, and everything else is secondary.</p>

      <div className='copy-block-div mb-6'>
        <p className='semibold-text mb-0'>Code</p>
        <CopyBlock
          language="html"
          text={`<div class="row top-form">
  <div class="col form-container">
    <div class="row main-form-row">
      ...
    </div>
    <div class="row">
      <div class="col d-flex justify-content-center shifted-button-div">
        <div class="shifted-div">
          <button type="button" class="btn btn-primary">Primary</button>
          <button type="button" class="btn btn-light">Light</button>
        </div>
      </div>
    </div>
  </div>
</div>`}
          theme={monoBlue}
          showLineNumbers={false}
          codeBlock
        />
      </div>


      <h2>Form Row Item Entry</h2>
      <p>There will be instances where you will be building a form or page containing groups of individual forms. The idea is you need to "add an entry" and it will create a group of form fields that sit as their own separate form with in this page. An example is when you put in base rates on a supplier.</p>
      <p>An easy solution is to use our Form Row Item Entry style of container.</p>
      <div className='container-fluid mb-4'>
        <div className="row">
          <div className="col-12 form-row-item-entry" id="entry01">
            <div className="row d-flex align-items-center">
              {/* Control Panel */}
              <div className="col-12 col-lg-2 control-panel">
                <div className="row">
                  <div className="col-auto col-sm-4 col-lg-12 pb-lg-2 form-element-container">
                    <div className='form-check'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        defaultValue='select01'
                        id='selectLabel01'
                        defaultChecked=''
                      />
                      <label className='form-check-label' htmlFor='selectLabel01'>
                        Select
                      </label>
                    </div>
                  </div>
                  <div className="col-auto col-sm-4 col-lg-12">
                    <p className="semibold-text">
                      <a href="#" className="disabled">
                        <i className="fas fa-save" aria-hidden="true" />
                        Save
                      </a>
                    </p>
                  </div>
                  <div className="col-auto col-sm-4 col-lg-12">
                    <p className="semibold-text">
                      <a href="#">
                        <i className="fas fa-trash" aria-hidden="true" />
                        Delete
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              {/* Entry Form */}
              <div className="col-12 col-lg-10 entry-form">
                <div className="row">
                  <div className="col-md-4 col-lg-3">
                    <div className="form-element-container">
                      <label htmlFor="datepickerMockup01">Datepicker Mockup 1</label>
                      <div className='input-group'>
                        <input
                          type='text'
                          className='form-control'
                          id='datepickerMockup01'
                          placeholder=''
                          aria-label='Datepicker Mockup 1'
                          aria-describedby='button-addon03'
                          defaultValue='MM/DD/YYYY'
                        />
                        <button className='btn btn-default' type='button' id='button-addon03'>
                          <i className='fa-solid fa-calendar-days' />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-lg-3">
                    <div className="form-element-container position-relative">
                      <label htmlFor="formDropdown01">Dropdown Mockup 1</label>
                      <input
                        type="text"
                        className="form-control"
                        id="formDropdown01"
                        defaultValue="Please Select"
                      />
                      {/*Caret for mockup dropdown*/}
                      <i
                        className="fa-solid fa-caret-down"
                        style={{
                          position: "absolute",
                          right: "1rem",
                          fontSize: "1rem",
                          top: "2.5rem",
                          color: "#1A2574"
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-4 col-lg-3">
                    <div className="form-element-container position-relative">
                      <label htmlFor="formDropdown02">Dropdown Mockup 2</label>
                      <input
                        type="text"
                        className="form-control"
                        id="formDropdown02"
                        defaultValue="Please Select"
                      />
                      {/*Caret for mockup dropdown*/}
                      <i
                        className="fa-solid fa-caret-down"
                        style={{
                          position: "absolute",
                          right: "1rem",
                          fontSize: "1rem",
                          top: "2.5rem",
                          color: "#1A2574"
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-3">
                    <div className="form-element-container">
                      <label htmlFor="uom01">Unit of Measure 1</label>
                      <div className="input-group">
                        <input
                          type="text"
                          id="uom01"
                          className="form-control"
                          aria-label="Unit of Measure Example"
                          aria-describedby="basic-addon01"
                        />
                        <span className="input-group-text half-blue" id="basic-addon01">
                          ¢/kWh
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4">
                    <div className="form-element-container">
                      <label htmlFor="uom02">Unit of Measure 2</label>
                      <div className="input-group">
                        <input
                          type="text"
                          id="uom02"
                          className="form-control"
                          aria-label="Unit of Measure 2"
                          aria-describedby="basic-addon02"
                        />
                        <span className="input-group-text half-blue" id="basic-addon02">
                          ¢/kWh
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4">
                    <div className="form-element-container">
                      <label htmlFor="uom03">Unit of Measure 3</label>
                      <div className="input-group">
                        <input
                          type="text"
                          id="uom03"
                          className="form-control"
                          aria-label="Unit of Measure 3"
                          aria-describedby="basic-addon03"
                        />
                        <span className="input-group-text half-blue" id="basic-addon03">
                          ¢/kWh
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4">
                    <div className="form-element-container">
                      <label htmlFor="uom04">Unit of Measure 4</label>
                      <div className="input-group">
                        <input
                          type="text"
                          id="uom04"
                          className="form-control"
                          aria-label="Unit of Measure 3"
                          aria-describedby="basic-addon03"
                        />
                        <span className="input-group-text half-blue" id="basic-addon03">
                          ¢/kWh
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p className='mb-4'>Here&rsquo;s a setup you can use. Please feel free to edit the column breakdowns and use any padding or margin you need to get the final result you want. The added controls along the side allow you to then save or delete the item, or select it via checkbox if you wish to do batch processes.</p>
      <div className='copy-block-div mb-6'>
        <p className='semibold-text mb-0'>Code</p>
        <CopyBlock
          language="html"
          text={`<div class="col-12 form-row-item-entry" id="entry01">
  <div class="row d-flex align-items-center">
    <div class="col-12 col-lg-2 control-panel">
      <div class="row">
        <div class="col-auto col-sm-4 col-lg-12 pb-lg-2 form-element-container">
          <div class="form-check"><input class="form-check-input" type="checkbox" id="selectLabel01" value="select01" /><label class="form-check-label" for="selectLabel01">Select</label></div>
        </div>
        <div class="col-auto col-sm-4 col-lg-12">
          <p class="semibold-text">
            <a href="#" class="disabled"><i class="fas fa-save" aria-hidden="true"></i>Save</a>
          </p>
        </div>
        <div class="col-auto col-sm-4 col-lg-12">
          <p class="semibold-text">
            <a href="#"><i class="fas fa-trash" aria-hidden="true"></i>Delete</a>
          </p>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-10 entry-form">
      ...
    </div>
  </div>
</div>`}
          theme={monoBlue}
          showLineNumbers={false}
          codeBlock
        />
      </div>

      <h2>Custom Collapse</h2>
      <p className='mb-4'>A collapse is a setup where you can show or hide a section with a click of a link. While Bootstrap has a simple collapse you can style almost in any fashion, this custom setup is one that we use frequently in different variations.</p>
      <div className='container-fluid mb-4'>
        <div className="row">
          <div className="col-12 custom-collapse mb-5">
            <h3 className="add-underline collapse-header">
              <a className="collapse-toggle collapsed" data-bs-toggle="collapse" href="#collapseExample01" role="button" aria-expanded="false" aria-controls="collapseExample01">
                Title of Collapse
                <span className="collapse-symbol float-end">
                  <span className="details-text">Details</span><i className="fa-solid fa-chevron-up" />
                </span>
              </a>
            </h3>
            <div className="custom-collapse-div collapse" id="collapseExample01">
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus euismod nisl vitae viverra imperdiet. Duis non venenatis quam. Aenean ac lobortis ex. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Sed ipsum nisl, tincidunt nec efficitur id, luctus ac ligula. Donec ac leo ac lectus.</p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 custom-collapse">
            <h4 className="collapse-header">
              <a className="collapse-toggle collapsed light-blue" data-bs-toggle="collapse" href="#collapseExample02" role="button" aria-expanded="false" aria-controls="collapseExample02">
                Title of Collapse
                <span className="collapse-symbol ms-1">
                  <i className="fa-solid fa-chevron-up" />
                </span>
              </a>
            </h4>
            <div className="custom-collapse-div collapse" id="collapseExample02">
              <hr className='divider1px' />
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus euismod nisl vitae viverra imperdiet. Duis non venenatis quam. Aenean ac lobortis ex. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Sed ipsum nisl, tincidunt nec efficitur id, luctus ac ligula. Donec ac leo ac lectus.</p>
            </div>
          </div>
        </div>
      </div>
      <p>The setup is versatile based on what you would prefer to do. The <span className='magenta-text'>.collapse-header</span> can be any header tag, a simple paragraph, or even a button if you prefer (<em>just don't use the <span className='magenta-text'>.collapse-header</span> class on the button</em>). You can use Bootstrap's float classes to send the chevron and &ldquo;details text&rdquo; to the right or leave them next to the title.</p>
      <p className='mb-4'>The <span className='magenta-text'>.details-text</span> class put into a <span className='magenta-text'>&lt;span&gt;</span> is set up so the text will disappear when the collapse is opened. You can use any text you wish in that <span className='magenta-text'>&lt;span&gt;</span>.</p>
      <div className='copy-block-div mb-6'>
        <p className='semibold-text mb-0'>Code</p>
        <CopyBlock
          language="html"
          text={`<div class="row">
  <div class="col-12 custom-collapse">
    <h3 class="add-underline collapse-header">
      <a class="collapse-toggle collapsed" data-toggle="collapse" href="#collapseExample01" role="button" aria-expanded="false" aria-controls="collapseExample01">
        Title of Collapse
        <span class="collapse-symbol float-right"><span class="details-text">Details</span><i class="fa-solid fa-chevron-up"></i><span>
      </a>
    </h3>
    <div class="custom-collapse-div collapse" id="collapseExample01">
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus euismod nisl vitae viverra imperdiet. Duis non venenatis quam. Aenean ac lobortis ex. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Sed ipsum nisl, tincidunt nec efficitur id, luctus ac ligula. Donec ac leo ac lectus.</p>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-12 custom-collapse">
    <h4 class="collapse-header">
      <a class="collapse-toggle collapsed" data-toggle="collapse" href="#collapseExample02" role="button" aria-expanded="false" aria-controls="collapseExample02">
        Title of Collapse
        <span class="collapse-symbol"><i class="fa-solid fa-chevron-up"></i><span>
      </a>
    </h4>
    <div class="custom-collapse-div collapse" id="collapseExample02">
      <hr class="divider1px">
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus euismod nisl vitae viverra imperdiet. Duis non venenatis quam. Aenean ac lobortis ex. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Sed ipsum nisl, tincidunt nec efficitur id, luctus ac ligula. Donec ac leo ac lectus.</p>
    </div>
  </div>
</div>`}
          theme={monoBlue}
          showLineNumbers={false}
          codeBlock
        />
      </div>
      <h3>Tabs VS Collapses</h3>
      <p>The subject has come up on whether to use collapses or tabs on a page when we show large amounts of content under one bucket. The rule should be to think about the role and function of each section. In a page like Account Management, where we have multiple web forms that handle different functions under one subject, tabs are ideal. However, in a section like Customer Engagement, collapsable sections work better, as all the sections still serve the single function of managing email content.</p>
      <p className='mb-5'>While there is no set rule on using tabs or collapsable areas, be cognizant of the end user, as you don't want them missing important content due to having to go through tabs to get their needs completed. Collapsable areas are ideal for organizing a lot of content on one page, while tabs are ideal for several separate functions on one page, and if you need mutiple pages to handle one function, consider splitting things up into <a href="/components/steps">steps</a>.</p>
    </>
  );
}

export default ContentDiv;