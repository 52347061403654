import './_dropdowns.scss';
import React from 'react';
import { CopyBlock, monoBlue } from "react-code-blocks";

function Dropdowns() {
  return (
    <>
      <h1>Dropdowns</h1>
      <p>Dropdown menus in our dashboard are not done with simple <span className='magenta-text'>&lt;select&gt;</span> setups, but a custom
        component made in Angular.  This gives us better stylized menus as well as more options.</p>
      <p className='mb-6'>The dropdowns are made using Bootstrap's dropdown component with some modification on our part. Included here are code examples for mockups as well as the full Angular component.</p>
      <h2>Form Field Dropdown</h2>
      <p>These menus are ideal for any situation actually involving a web form.</p>
      <div className='container-fluid mb-4'>
        <div className='row'>
          <div className='col col-md-6 col-xxxl-5 form-element-container'>
            <label htmlFor='formDropdownExample'>Form Dropdown Example</label>
            <div className='dropdown flex-fill pseudo-form-control'>
              <a
                className='dropdown-toggle'
                href='#'
                role='button'
                id='formDropdownExample'
                data-bs-toggle='dropdown'
                aria-expanded='false'
              >
                Please Select
              </a>
              <div
                className='dropdown-menu'
                aria-labelledby='formDropdownExample'
                x-placement='bottom-start'
              >
                <a className='dropdown-item' href='#'>
                  Choice One
                </a>
                <a className='dropdown-item' href='#'>
                  Choice Two
                </a>
                <a className='dropdown-item' href='#'>
                  Choice Three
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p className='mb-4'>For design or prototyping purposes, you only need to show the simple placement mockup, but you can do fully working ones if need be:</p>
      <ul className="nav nav-tabs mb-4" id="formDdTabs" role="tablist">
        <li className="nav-item" role="presentation">
          <a
            className="nav-link active"
            id="home-tab"
            data-bs-toggle="tab"
            data-bs-target="#formPlacementMockup"
            href="formPlacementMockup"
            role="tab"
            aria-controls="formPlacementMockup"
            aria-selected="true"
          >
            Placement Mockup
          </a>
        </li>
        <li className="nav-item" role="presentation">
          <a
            className="nav-link"
            id="home-tab"
            data-bs-toggle="tab"
            data-bs-target="#formWorkingMockup"
            href="formWorkingMockup"
            role="tab"
            aria-controls="formWorkingMockup"
            aria-selected="false"
          >
            Working Mockup
          </a>
        </li>
        <li className="nav-item" role="presentation">
          <a
            className="nav-link"
            id="home-tab"
            data-bs-toggle="tab"
            data-bs-target="#formAngularComp"
            href="formAngularComp"
            role="tab"
            aria-controls="formAngularComp"
            aria-selected="false"
          >
            Angular Component
          </a>
        </li>
      </ul>
      <div className="tab-content mb-5" id="formDdContent">
        <div
          className="tab-pane fade mb-5 show active"
          id="formPlacementMockup"
          role="tabpanel"
          aria-labelledby="formPlacement"
          tabIndex={0}
        >
          <div className='copy-block-div'>
            <p className='semibold-text mb-0'>Code</p>
            <CopyBlock
              language="html"
              text={`<div class="form-element-container">
    <label for="formDropdownExample">Form Dropdown Example</label>
    <input type="text" class="form-control" id="formDropdownExample" value="Please Select">
    <!--Caret for mockup dropdown-->
    <i class="fa-solid fa-caret-down" style="position: absolute;right: 1.5rem;font-size: 1rem;top: 2.5rem;color: #1A2574;" aria-hidden="true"></i>
</div>`}
              theme={monoBlue}
              showLineNumbers={false}
              codeBlock
            />
          </div>
        </div>
        <div
          className="tab-pane fade"
          id="formWorkingMockup"
          role="tabpanel"
          aria-labelledby="formWorking"
          tabIndex={0}
        >
          <div className='copy-block-div mb-3'>
            <p className='semibold-text mb-0'>HTML</p>
            <CopyBlock
              language="html"
              text={`<div class="form-element-container">
    <label for="formDropdownExample">Form Dropdown Example</label>
    <div class="dropdown flex-fill pseudo-form-control">
        <a class="dropdown-toggle" href="#" role="button" id="formDropdownExample" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Please Select
        </a>
        <div class="dropdown-menu" aria-labelledby="formDropdownExample">
            <a class="dropdown-item" href="#">Choice One</a>
            <a class="dropdown-item" href="#">Choice Two</a>
            <a class="dropdown-item" href="#">Choice Three</a>
        </div>
    </div>
</div>`}
              theme={monoBlue}
              showLineNumbers={false}
              codeBlock
            />
          </div>
          <div className='copy-block-div add-y-scroll'>
            <p className='semibold-text mb-0'>CSS</p>
            <CopyBlock
              language="css"
              text={`.pseudo-form-control .dropdown-toggle {
    font-size: 0.9rem;
    background: #e9eef5;
    padding: 0.6em 1em;
    display: block;
    border-radius: 0.25rem;
}

.pseudo-form-control .dropdown-toggle:link, .pseudo-form-control .dropdown-toggle:visited, .pseudo-form-control .dropdown-toggle:active, .pseudo-form-control .dropdown-toggle:hover, .pseudo-form-control .dropdown-toggle:focus {
    color: #6c757d;
    text-decoration: none;
}

.pseudo-form-control .dropdown-toggle:after {
    color: #1a2574;
    float: right;
    margin-top: 0.5rem;
}

.pseudo-form-control .dropdown-menu {
    font-size: 0.8rem;
    border: 1px solid #d5dfec;
    border-radius: 0.5rem;
    -webkit-box-shadow: 2px 2px 5px 0px rgba(50, 50, 50, 0.5);
    -moz-box-shadow: 2px 2px 5px 0px rgba(50, 50, 50, 0.5);
    box-shadow: 2px 2px 5px 0px rgba(50, 50, 50, 0.5);
}

.pseudo-form-control .dropdown-menu .dropdown-item {
    padding: .25rem 1rem;
}

.pseudo-form-control .dropdown-menu .dropdown-item:link, .pseudo-form-control .dropdown-menu .dropdown-item:visited, .pseudo-form-control .dropdown-menu .dropdown-item:active {
    color: #707070;
    background-color: #ffffff;
}

.pseudo-form-control .dropdown-menu .dropdown-item:link.semibold-link, .pseudo-form-control .dropdown-menu .dropdown-item:visited.semibold-link, .pseudo-form-control .dropdown-menu .dropdown-item:active.semibold-link {
    font-weight: 600;
}

.pseudo-form-control .dropdown-menu .dropdown-item:link.selected-item, .pseudo-form-control .dropdown-menu .dropdown-item:visited.selected-item, .pseudo-form-control .dropdown-menu .dropdown-item:active.selected-item {
    font-weight: 600;
    color: #707070;
    background-color: #ebebeb;
}

.pseudo-form-control .dropdown-menu .dropdown-item:link.selected-item .set-default-link, .pseudo-form-control .dropdown-menu .dropdown-item:visited.selected-item .set-default-link, .pseudo-form-control .dropdown-menu .dropdown-item:active.selected-item .set-default-link {
    color: #12a5ff;
    margin-left: 0.5rem;
}

.pseudo-form-control .dropdown-menu .dropdown-item:hover, .pseudo-form-control .dropdown-menu .dropdown-item:focus {
    background-color: #d5dfec;
}

.pseudo-form-control .dropdown-menu .dropdown-item:hover.selected-item .set-default-link, .pseudo-form-control .dropdown-menu .dropdown-item:focus.selected-item .set-default-link {
    color: #1a2574;
}

.pseudo-form-control .dropdown-menu.scroll-dropdown {
    padding: 1rem;
    min-width: 18rem;
}

.pseudo-form-control .dropdown-menu.scroll-dropdown .scrolling-area {
    max-height: 20rem;
    overflow-y: scroll;
}`}
              theme={monoBlue}
              showLineNumbers={false}
              codeBlock
            />
          </div>
        </div>
        <div
          className="tab-pane fade"
          id="formAngularComp"
          role="tabpanel"
          aria-labelledby="formAngular"
          tabIndex={0}
        >
          <div className='copy-block-div'>
            <p className='semibold-text mb-0'>Code</p>
            <CopyBlock
              language="html"
              text={`<pk-broker-form-dropdown
    label="Form Dropdown Example"
    [isRequired]="false"
    formControlName="formDropdownExampleId"
    [options]="formDropdownExample"
    textKey="name"
    valueKey="formDropdownExampleId"
    nullOption="Please Select"
    [nullOptionSelectable]="false">
</pk-broker-form-dropdown>`}
              theme={monoBlue}
              showLineNumbers={false}
              codeBlock
            />
          </div>
        </div>
      </div>

      <h3>Working Mockup</h3>
      <p>You will need place the <span className='magenta-text'>.form-element-container</span> class on the containing div in order to apply all the stylings.
        The <span className='magenta-text'>.pseudo-form-control</span> class makes the toggle hyperlink look like a form element. The dropdown classes are from
        Bootstrap and have been enhanced to give us the final look.</p>
      <p className='mb-5'>You can also put a scroll on the dropdown using the configuration in the second example on the Working Mockup. You just
        add <span className='magenta-text'>.scroll-dropdown</span> to the <span className='magenta-text'>.dropdown-menu</span> div, and wrap the items in a div
        with the <span className='magenta-text'>.scrolling-area class</span>.</p>
      <h3>Angular Component</h3>
      <p className='semibold-text'>You won't need this unless you're part of the development team. Designers and prototypers should simply stick to the Placement Mockup or Working Mockup.</p>
      <p>You can copy and paste the code in the example and set the parameters as follows:</p>
      <ul>
        <li><span className='magenta-text'>label</span> [string] represents the text that shows up on the label for the form element.</li>
        <li><span className='magenta-text'>isRequired</span> [boolean] defaults as false. Setting to "true" will put the red asterik by the label to show it's a required field.</li>
        <li><span className='magenta-text'>nullOption</span> [string] is the default option that shows up in the form field when you have not selected anything, like "Please Select". The default for this parameter is to not show anything.</li>
        <li><span className='magenta-text'>nullOptionSelectable</span> [boolean] If the nullOption is shown in the dropdown menu, defaults to true.</li>
      </ul>
      <p className='mb-6'><span className="semibold-text">Note:</span> string attributes look like this: <span className='magenta-text'>label="Service type"</span>, and boolean attributes look like this: <span className='magenta-text'>[isRequired]="true"</span> (with brackets)</p>
      <h2>Filter Dropdown</h2>
      <p>These menus are ideal for filters on tables or any kinds of results. Small compact dropdown menus when you need them.</p>
      <div className='container-fluid mb-4'>
        <div className='row'>
          <div className='col'>
            <div className='dropdown compact-select'>
              <label className='compact-select-label me-2' for='filterDropdownExample'>Filter Dropdown Example</label>
              <a
                className='dropdown-toggle'
                href='#'
                role='button'
                id='filterDropdownExample'
                data-bs-toggle='dropdown'
                aria-haspopup='true'
                aria-expanded='false'
              >
                Choice One
              </a>
              <div
                className='dropdown-menu'
                aria-labelledby='filterDropdownExample'
                x-placement='bottom-start'
              >
                <a className='dropdown-item' href='#'>
                  Choice One
                </a>
                <a className='dropdown-item' href='#'>
                  Choice Two
                </a>
                <a className='dropdown-item' href='#'>
                  Choice Three
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p className='mb-4'>For design or prototyping purposes, you only need to show the simple placement mockup, but you can do fully working ones if need be:</p>

      <ul className="nav nav-tabs mb-4" id="filterDdTabs" role="tablist">
        <li className="nav-item" role="presentation">
          <a
            className="nav-link active"
            id="home-tab"
            data-bs-toggle="tab"
            data-bs-target="#filterPlacementMockup"
            href="filterPlacementMockup"
            role="tab"
            aria-controls="filterPlacementMockup"
            aria-selected="true"
          >
            Placement Mockup
          </a>
        </li>
        <li className="nav-item" role="presentation">
          <a
            className="nav-link"
            id="home-tab"
            data-bs-toggle="tab"
            data-bs-target="#filterWorkingMockup"
            href="filterWorkingMockup"
            role="tab"
            aria-controls="filterWorkingMockup"
            aria-selected="false"
          >
            Working Mockup
          </a>
        </li>
        <li className="nav-item" role="presentation">
          <a
            className="nav-link"
            id="home-tab"
            data-bs-toggle="tab"
            data-bs-target="#filterAngularComp"
            href="filterAngularComp"
            role="tab"
            aria-controls="filterAngularComp"
            aria-selected="false"
          >
            Angular Component
          </a>
        </li>
      </ul>
      <div className="tab-content mb-5" id="filterDdContent">
        <div
          className="tab-pane fade mb-5 show active"
          id="filterPlacementMockup"
          role="tabpanel"
          aria-labelledby="filterPlacement"
          tabIndex={0}
        >
          <div className='copy-block-div'>
            <p className='semibold-text mb-0'>Code</p>
            <CopyBlock
              language="html"
              text={`<p class="font-size-085">
    Filter Dropdown Example 
    <span class="blue-text semibold-text">Choice One
        <i class="fa-solid fa-caret-down" style="position: absolute;right: 0;font-size: 1rem;top: 0;color: #1A2574;" aria-hidden="true"></i>
    </span>
</p>`}
              theme={monoBlue}
              showLineNumbers={false}
              codeBlock
            />
          </div>
        </div>
        <div
          className="tab-pane fade"
          id="filterWorkingMockup"
          role="tabpanel"
          aria-labelledby="filterWorking"
          tabIndex={0}
        >
          <div className='copy-block-div mb-3'>
            <p className='semibold-text mb-0'>HTML</p>
            <CopyBlock
              language="html"
              text={`<div class="dropdown compact-select">
    <label class="compact-select-label">Filter Dropdown Example</label>
    <a class="dropdown-toggle" href="#" role="button" id="filterDropdownExample" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        Choice One
    </a>
    <div class="dropdown-menu" aria-labelledby="filterDropdownExample">
        <a class="dropdown-item" href="#">Choice One</a>
        <a class="dropdown-item" href="#">Choice Two</a>
        <a class="dropdown-item" href="#">Choice Three</a>
    </div>
</div>`}
              theme={monoBlue}
              showLineNumbers={false}
              codeBlock
            />
          </div>
          <div className='copy-block-div add-y-scroll'>
            <p className='semibold-text mb-0'>CSS</p>
            <CopyBlock
              language="css"
              text={`.compact-select .dropdown-toggle {
  font-size: 0.85rem;
  font-weight: 600;
}

.compact-select .dropdown-toggle:link, .compact-select .dropdown-toggle:visited, .compact-select .dropdown-toggle:active {
  color: #1a2574;
  text-decoration: none;
}

.compact-select .dropdown-toggle:hover, .compact-select .dropdown-toggle:focus {
  color: #12a5ff;
  text-decoration: none;
}

.compact-select .compact-select-label {
  font-size: 0.85rem;
}

.compact-select .dropdown-menu {
  font-size: 0.8rem;
  border: 1px solid #d5dfec;
  border-radius: 0.5rem;
  -webkit-box-shadow: 2px 2px 5px 0px rgba(50, 50, 50, 0.5);
  -moz-box-shadow: 2px 2px 5px 0px rgba(50, 50, 50, 0.5);
  box-shadow: 2px 2px 5px 0px rgba(50, 50, 50, 0.5);
}

.compact-select .dropdown-menu .dropdown-item {
  padding: .25rem 1rem;
}

.compact-select .dropdown-menu .dropdown-item:link, .compact-select .dropdown-menu .dropdown-item:visited, .compact-select .dropdown-menu .dropdown-item:active {
  color: #707070;
  background-color: #ffffff;
}

.compact-select .dropdown-menu .dropdown-item:link.semibold-link, .compact-select .dropdown-menu .dropdown-item:visited.semibold-link, .compact-select .dropdown-menu .dropdown-item:active.semibold-link {
  font-weight: 600;
}

.compact-select .dropdown-menu .dropdown-item:link.selected-item, .compact-select .dropdown-menu .dropdown-item:visited.selected-item, .compact-select .dropdown-menu .dropdown-item:active.selected-item {
  font-weight: 600;
  color: #707070;
  background-color: #ebebeb;
}

.compact-select .dropdown-menu .dropdown-item:link.selected-item .set-default-link, .compact-select .dropdown-menu .dropdown-item:visited.selected-item .set-default-link, .compact-select .dropdown-menu .dropdown-item:active.selected-item .set-default-link {
  color: #12a5ff;
  margin-left: 0.5rem;
}

.compact-select .dropdown-menu .dropdown-item:hover, .compact-select .dropdown-menu .dropdown-item:focus {
  background-color: #d5dfec;
}

.compact-select .dropdown-menu .dropdown-item:hover.selected-item .set-default-link, .compact-select .dropdown-menu .dropdown-item:focus.selected-item .set-default-link {
  color: #1a2574;
}

.compact-select .dropdown-menu .dropdown-item .energy-icon {
  height: 1.5rem;
  width: auto;
  margin: 0 0.5rem 0 0;
  vertical-align: bottom;
}

.compact-select .dropdown-menu.scroll-dropdown {
  padding: 1rem;
  min-width: 18rem;
}

.compact-select .dropdown-menu.scroll-dropdown .scrolling-area {
  max-height: 20rem;
  overflow-y: scroll;
}`}
              theme={monoBlue}
              showLineNumbers={false}
              codeBlock
            />
          </div>
        </div>
        <div
          className="tab-pane fade"
          id="filterAngularComp"
          role="tabpanel"
          aria-labelledby="filterAngular"
          tabIndex={0}
        >
          <div className='copy-block-div'>
            <p className='semibold-text mb-0'>Code</p>
            <CopyBlock
              language="html"
              text={`<pk-broker-filter-dropdown
    label="Filter Dropdown Example"
    [isRequired]="false"
    formControlName="filterDropdownExampleId"
    [options]="filterDropdownExample"
    textKey="name"
    valueKey="filterDropdownExampleId"
    nullOption="Please Select"
    [nullOptionSelectable]="false">
</pk-broker-filter-dropdown>`}
              theme={monoBlue}
              showLineNumbers={false}
              codeBlock
            />
          </div>
        </div>
      </div>
      <h3>Configuration</h3>
      <p>You do not need to place the <span className='magenta-text'>.form-element-container</span> class on the containing div for these dropdowns.</p>
      <p className='mb-5'>All of the same CSS classes and configurations for the dropdown menu portion are available to be used here. If you choose to use a multi-select kind of dropdown,
        then omit using the label, and instead have the toggle be the label where your choices are not reflected in the toggle.</p>
      <p>Configuration of the Angular component is the same as for the Form Dropdown.</p>
      <h3>Usage Guidelines</h3>
      <ul>
        <li>Use the form dropdowns in web forms, and refrain from using filter dropdowns in web forms unless you need something compact.</li>
        <li>Do not use filter dropdowns on any primary form components, but only for secondary.</li>
        <li>Do not use form dropdowns as filters.</li>
        <li>Make sure the Development Team knows of all the choices that need to appear in the dropdown when creating the Integration Ticket.</li>
      </ul>
    </>
  );
}

export default Dropdowns;