export const brokerPayments = [{
  ID: 1,
  Broker: "Michael Rogers",
  State: "Illinois",
  Forecasted: 1234.56,
  Actuals: 1123.45
}, {
  ID: 2,
  Broker: "Carlos Miller",
  State: "Indiana",
  Forecasted: 789.01,
  Actuals: 778.90
}, {
  ID: 3,
  Broker: "Nicole Edwards",
  State: "Texas",
  Forecasted: 2345.67,
  Actuals: 2123.45
}, {
  ID: 4,
  Broker: "Steven Davis",
  State: "New York",
  Forecasted: 1567.89,
  Actuals: 1564.56
}];