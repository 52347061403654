import './_colors-overview.scss';
import React from 'react';

function ColorsOverview() {
  return (
    <>
      <div className='row'>
        <div className='col'>
          <h1 className='mb-5'>Colors</h1>
          <h2>Primary Brand Colors</h2>
        </div>
      </div>
      <div className='row mb-5'>
        <div className='col-12 col-sm-6 col-md-4 col-lg-3'>
          <div className='card color-swatch pk-blue'>
            <div className='card-body'>
              <h3 className='card-title'>Power Kiosk Blue</h3>
              <p>Hex: <span className='semibold-text'>#1A2574</span></p>
              <p>RGB: <span className='semibold-text'>26 37 116</span></p>
              <p>CMYK: <span className='semibold-text'>100 97 24 12</span></p>
            </div>
          </div>
        </div>
        <div className='col-12 col-sm-6 col-md-4 col-lg-3'>
          <div className='card color-swatch orange'>
            <div className='card-body'>
              <h3 className='card-title'>Orange</h3>
              <p>Hex: <span className='semibold-text'>#FC8F3B</span></p>
              <p>RGB: <span className='semibold-text'>252 143 59</span></p>
              <p>CMYK: <span className='semibold-text'>0 53 84 0</span></p>
            </div>
          </div>
        </div>
        <div className='col-12 col-sm-6 col-md-4 col-lg-3'>
          <div className='card color-swatch black'>
            <div className='card-body'>
              <h3 className='card-title'>Black</h3>
              <p>Hex: <span className='semibold-text'>#000000</span></p>
              <p>RGB: <span className='semibold-text'>0 0 0</span></p>
              <p>CMYK: <span className='semibold-text'>75 68 67 90</span></p>
            </div>
          </div>
        </div>
        <div className='col-12 col-sm-6 col-md-4 col-lg-3'>
          <div className='card color-swatch white'>
            <div className='card-body'>
              <h3 className='card-title'>White</h3>
              <p>Hex: <span className='semibold-text'>#FFFFFF</span></p>
              <p>RGB: <span className='semibold-text'>255 255 255</span></p>
              <p>CMYK: <span className='semibold-text'>0 0 0 0</span></p>
            </div>
          </div>
        </div>
        <div className='col-12 col-sm-6 col-md-4 col-lg-3'>
          <div className='card color-swatch dark-blue'>
            <div className='card-body'>
              <h3 className='card-title'>Dark Blue</h3>
              <p>Hex: <span className='semibold-text'>#214A86</span></p>
              <p>RGB: <span className='semibold-text'>33 74 134</span></p>
              <p>CMYK: <span className='semibold-text'>97 80 19 5</span></p>
            </div>
          </div>
        </div>
        <div className='col-12 col-sm-6 col-md-4 col-lg-3'>
          <div className='card color-swatch light-blue'>
            <div className='card-body'>
              <h3 className='card-title'>Light Blue</h3>
              <p>Hex: <span className='semibold-text'>#12A5ff</span></p>
              <p>RGB: <span className='semibold-text'>18 165 255</span></p>
              <p>CMYK: <span className='semibold-text'>67 26 0 0</span></p>
            </div>
          </div>
        </div>
        <div className='col-12 col-sm-6 col-md-4 col-lg-3'>
          <div className='card color-swatch gray'>
            <div className='card-body'>
              <h3 className='card-title'>Gray</h3>
              <p>Hex: <span className='semibold-text'>#D5DFEC</span></p>
              <p>RGB: <span className='semibold-text'>213 223 236</span></p>
              <p>CMYK: <span className='semibold-text'>15 7 2 0</span></p>
            </div>
          </div>
        </div>
        <div className='col-12 col-sm-6 col-md-4 col-lg-3'>
          <div className='card color-swatch dark-gray'>
            <div className='card-body'>
              <h3 className='card-title'>Dark Gray</h3>
              <p>Hex: <span className='semibold-text'>#707070</span></p>
              <p>RGB: <span className='semibold-text'>112 112 112</span></p>
              <p>CMYK: <span className='semibold-text'>57 48 48 15</span></p>
            </div>
          </div>
        </div>
        <div className='col-12 col-sm-6 col-md-4 col-lg-3'>
          <div className='card color-swatch light-gray'>
            <div className='card-body'>
              <h3 className='card-title'>Light Gray</h3>
              <p>Hex: <span className='semibold-text'>#EBEBEB</span></p>
              <p>RGB: <span className='semibold-text'>235 235 235</span></p>
              <p>CMYK: <span className='semibold-text'>7 5 5 0</span></p>
            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <h2>Secondary Colors</h2>
        </div>
      </div>
      <div className='row mb-5'>
        <div className='col-12 col-sm-6 col-md-4 col-lg-3'>
          <div className='card color-swatch blue'>
            <div className='card-body'>
              <h3 className='card-title'>Medium Blue</h3>
              <p>Hex: <span className='semibold-text'>#27569C</span></p>
              <p>RGB: <span className='semibold-text'>39 86 156</span></p>
              <p>CMYK: <span className='semibold-text'>93 73 7 1</span></p>
            </div>
          </div>
        </div>
        <div className='col-12 col-sm-6 col-md-4 col-lg-3'>
          <div className='card color-swatch half-blue'>
            <div className='card-body'>
              <h3 className='card-title'>Half Blue</h3>
              <p>Hex: <span className='semibold-text'>#E9EEF5</span></p>
              <p>RGB: <span className='semibold-text'>233 238 245</span></p>
              <p>CMYK: <span className='semibold-text'>7 3 1 0</span></p>
            </div>
          </div>
        </div>
        <div className='col-12 col-sm-6 col-md-4 col-lg-3'>
          <div className='card color-swatch diamond'>
            <div className='card-body'>
              <h3 className='card-title'>Diamond</h3>
              <p>Hex: <span className='semibold-text'>#B9F2FF</span></p>
              <p>RGB: <span className='semibold-text'>185 242 255</span></p>
              <p>CMYK: <span className='semibold-text'>23 0 2 0</span></p>
            </div>
          </div>
        </div>
        <div className='col-12 col-sm-6 col-md-4 col-lg-3'>
          <div className='card color-swatch faint-blue'>
            <div className='card-body'>
              <h3 className='card-title'>Faint Blue</h3>
              <p>Hex: <span className='semibold-text'>#F2F8FF</span></p>
              <p>RGB: <span className='semibold-text'>242 248 255</span></p>
              <p>CMYK: <span className='semibold-text'>4 1 0 0</span></p>
            </div>
          </div>
        </div>
        <div className='col-12 col-sm-6 col-md-4 col-lg-3'>
          <div className='card color-swatch off-white'>
            <div className='card-body'>
              <h3 className='card-title'>Off-White</h3>
              <p>Hex: <span className='semibold-text'>#FAFAFA</span></p>
              <p>RGB: <span className='semibold-text'>250 250 250</span></p>
              <p>CMYK: <span className='semibold-text'>1 1 1 0</span></p>
            </div>
          </div>
        </div>
        <div className='col-12 col-sm-6 col-md-4 col-lg-3'>
          <div className='card color-swatch silver'>
            <div className='card-body'>
              <h3 className='card-title'>Silver</h3>
              <p>Hex: <span className='semibold-text'>#D4D2C9</span></p>
              <p>RGB: <span className='semibold-text'>212 210 201</span></p>
              <p>CMYK: <span className='semibold-text'>16 13 18 0</span></p>
            </div>
          </div>
        </div>
        <div className='col-12 col-sm-6 col-md-4 col-lg-3'>
          <div className='card color-swatch off-row-gray'>
            <div className='card-body'>
              <h3 className='card-title'>Off-Row Gray</h3>
              <p>Hex: <span className='semibold-text'>#F5F5F5</span></p>
              <p>RGB: <span className='semibold-text'>245 245 245</span></p>
              <p>CMYK: <span className='semibold-text'>3 2 2 0</span></p>
            </div>
          </div>
        </div>
        <div className='col-12 col-sm-6 col-md-4 col-lg-3'>
          <div className='card color-swatch outline-gray'>
            <div className='card-body'>
              <h3 className='card-title'>Outline Gray</h3>
              <p>Hex: <span className='semibold-text'>#BABEC2</span></p>
              <p>RGB: <span className='semibold-text'>186 190 194</span></p>
              <p>CMYK: <span className='semibold-text'>27 20 18 0</span></p>
            </div>
          </div>
        </div>
        <div className='col-12 col-sm-6 col-md-4 col-lg-3'>
          <div className='card color-swatch darker-outline-gray'>
            <div className='card-body'>
              <h3 className='card-title'>Darker Outline Gray</h3>
              <p>Hex: <span className='semibold-text'>#A1A1A1</span></p>
              <p>RGB: <span className='semibold-text'>161 161 161</span></p>
              <p>CMYK: <span className='semibold-text'>39 32 32 1</span></p>
            </div>
          </div>
        </div>
        <div className='col-12 col-sm-6 col-md-4 col-lg-3'>
          <div className='card color-swatch blue-gray'>
            <div className='card-body'>
              <h3 className='card-title'>Blue Gray</h3>
              <p>Hex: <span className='semibold-text'>#6C91A7</span></p>
              <p>RGB: <span className='semibold-text'>108 145 167</span></p>
              <p>CMYK: <span className='semibold-text'>62 34 25 1</span></p>
            </div>
          </div>
        </div>
        <div className='col-12 col-sm-6 col-md-4 col-lg-3'>
          <div className='card color-swatch placeholder-gray'>
            <div className='card-body'>
              <h3 className='card-title'>Placeholder Gray</h3>
              <p>Hex: <span className='semibold-text'>#6C757D</span></p>
              <p>RGB: <span className='semibold-text'>108 117 125</span></p>
              <p>CMYK: <span className='semibold-text'>61 47 41 10</span></p>
            </div>
          </div>
        </div>
        <div className='col-12 col-sm-6 col-md-4 col-lg-3'>
          <div className='card color-swatch icon-link-blue'>
            <div className='card-body'>
              <h3 className='card-title'>Icon Link Blue</h3>
              <p>Hex: <span className='semibold-text'>#1E91D6</span></p>
              <p>RGB: <span className='semibold-text'>30 145 214</span></p>
              <p>CMYK: <span className='semibold-text'>76 31 0 0</span></p>
            </div>
          </div>
        </div>
        <div className='col-12 col-sm-6 col-md-4 col-lg-3'>
          <div className='card color-swatch tooltip-blue'>
            <div className='card-body'>
              <h3 className='card-title'>Tooltip Blue</h3>
              <p>Hex: <span className='semibold-text'>#CFE1FB</span></p>
              <p>RGB: <span className='semibold-text'>207 225 251</span></p>
              <p>CMYK: <span className='semibold-text'>16 6 0 0</span></p>
            </div>
          </div>
        </div>
        <div className='col-12 col-sm-6 col-md-4 col-lg-3'>
          <div className='card color-swatch green'>
            <div className='card-body'>
              <h3 className='card-title'>Green</h3>
              <p>Hex: <span className='semibold-text'>#009900</span></p>
              <p>RGB: <span className='semibold-text'>0 153 0</span></p>
              <p>CMYK: <span className='semibold-text'>84 13 100 3</span></p>
            </div>
          </div>
        </div>
        <div className='col-12 col-sm-6 col-md-4 col-lg-3'>
          <div className='card color-swatch light-green'>
            <div className='card-body'>
              <h3 className='card-title'>Light Green</h3>
              <p>Hex: <span className='semibold-text'>#DDF5DD</span></p>
              <p>RGB: <span className='semibold-text'>221 245 221</span></p>
              <p>CMYK: <span className='semibold-text'>13 0 16 0</span></p>
            </div>
          </div>
        </div>
        <div className='col-12 col-sm-6 col-md-4 col-lg-3'>
          <div className='card color-swatch faint-green'>
            <div className='card-body'>
              <h3 className='card-title'>Faint Green</h3>
              <p>Hex: <span className='semibold-text'>#F1FFF1</span></p>
              <p>RGB: <span className='semibold-text'>241 255 241</span></p>
              <p>CMYK: <span className='semibold-text'>4 0 7 0</span></p>
            </div>
          </div>
        </div>
        <div className='col-12 col-sm-6 col-md-4 col-lg-3'>
          <div className='card color-swatch yellow'>
            <div className='card-body'>
              <h3 className='card-title'>Yellow</h3>
              <p>Hex: <span className='semibold-text'>#FFE600</span></p>
              <p>RGB: <span className='semibold-text'>255 230 0</span></p>
              <p>CMYK: <span className='semibold-text'>2 4 99 0</span></p>
            </div>
          </div>
        </div>
        <div className='col-12 col-sm-6 col-md-4 col-lg-3'>
          <div className='card color-swatch red'>
            <div className='card-body'>
              <h3 className='card-title'>Red</h3>
              <p>Hex: <span className='semibold-text'>#FF0000</span></p>
              <p>RGB: <span className='semibold-text'>255 0 0</span></p>
              <p>CMYK: <span className='semibold-text'>0 99 100 0</span></p>
            </div>
          </div>
        </div>
        <div className='col-12 col-sm-6 col-md-4 col-lg-3'>
          <div className='card color-swatch pink'>
            <div className='card-body'>
              <h3 className='card-title'>Pink</h3>
              <p>Hex: <span className='semibold-text'>#F5DDDD</span></p>
              <p>RGB: <span className='semibold-text'>245 221 221</span></p>
              <p>CMYK: <span className='semibold-text'>2 14 7 0</span></p>
            </div>
          </div>
        </div>
        <div className='col-12 col-sm-6 col-md-4 col-lg-3'>
          <div className='card color-swatch faint-pink'>
            <div className='card-body'>
              <h3 className='card-title'>Faint Pink</h3>
              <p>Hex: <span className='semibold-text'>#FFF1F1</span></p>
              <p>RGB: <span className='semibold-text'>255 241 241</span></p>
              <p>CMYK: <span className='semibold-text'>0 6 2 0</span></p>
            </div>
          </div>
        </div>
        <div className='col-12 col-sm-6 col-md-4 col-lg-3'>
          <div className='card color-swatch faint-orange'>
            <div className='card-body'>
              <h3 className='card-title'>Faint Orange</h3>
              <p>Hex: <span className='semibold-text'>#FFF2D9</span></p>
              <p>RGB: <span className='semibold-text'>255 242 217</span></p>
              <p>CMYK: <span className='semibold-text'>0 4 15 0</span></p>
            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <h2>CSS Color Classes</h2>
          <p>The default text color for the dashboard is Dark Gray, but there are a number of CSS classes you can use to change the color of almost anything that uses a color property in its style:</p>
          <table className='table mt-2 mb-5'>
            <thead>
              <tr>
                <th scope="col">CSS Class</th>
                <th scope="col">Example</th>
              </tr>
            </thead>
            <tbody>
            <tr>
                <td scope="row" className='magenta-text'>.blue-text</td>
                <td className='blue-text semibold-text'>Power Kiosk Blue</td>
              </tr>
              <tr>
                <td scope="row" className='magenta-text'>.reg-blue-text</td>
                <td className='reg-blue-text semibold-text'>Medium Blue</td>
              </tr>
              <tr>
                <td scope="row" className='magenta-text'>.red-text</td>
                <td className='red-text semibold-text'>Red</td>
              </tr>
              <tr>
                <td scope="row" className='magenta-text'>.orange-text</td>
                <td className='orange-text semibold-text'>Orange</td>
              </tr>
              <tr>
                <td scope="row" className='magenta-text'>.green-text</td>
                <td className='green-text semibold-text'>Green</td>
              </tr>
              <tr>
                <td scope="row" className='magenta-text'>.white-text</td>
                <td className='placeholder-gray-bg white-text semibold-text'>White</td>
              </tr>
              <tr>
                <td scope="row" className='magenta-text'>.black-text</td>
                <td className='black-text semibold-text'>Black</td>
              </tr>
              <tr>
                <td scope="row" className='magenta-text'>.gray-text</td>
                <td className='gray-text semibold-text'>Dark Gray</td>
              </tr>
              <tr>
                <td scope="row" className='magenta-text'>.icon-blue-text</td>
                <td className='icon-blue-text semibold-text'>Icon Link Blue</td>
              </tr>
              <tr>
                <td scope="row" className='magenta-text'>.dark-blue-text</td>
                <td className='dark-blue-text semibold-text'>Dark Blue</td>
              </tr>
            </tbody>
          </table>
          <h2>CSS Background Color Classes</h2>
          <p>We also have a handful of CSS classes that allow you to change the background color of almost anything that uses a background-color property in its style:</p>
          <table className='table mt-2 mb-5'>
            <thead>
              <tr>
                <th scope="col">CSS Class</th>
                <th scope="col">Example</th>
              </tr>
            </thead>
            <tbody>
            <tr>
                <td scope="row" className='magenta-text'>.white-bg</td>
                <td className='white-bg'>White Background</td>
              </tr>
              <tr>
                <td scope="row" className='magenta-text'>.off-row-gray-bg</td>
                <td className='off-row-gray-bg'>Off-Row Gray Background</td>
              </tr>
              <tr>
                <td scope="row" className='magenta-text'>.half-blue-bg</td>
                <td className='half-blue-bg'>Half-Blue Background</td>
              </tr>
              <tr>
                <td scope="row" className='magenta-text'>.outline-gray-bg</td>
                <td className='outline-gray-bg'>Outline Gray Background</td>
              </tr>
              <tr>
                <td scope="row" className='magenta-text'>.light-gray-bg</td>
                <td className='light-gray-bg'>Light Gray Background</td>
              </tr>
              <tr>
                <td scope="row" className='magenta-text'>.placeholder-gray-bg</td>
                <td className='placeholder-gray-bg white-text'>Placeholder Gray Background</td>
              </tr>
              <tr>
                <td scope="row" className='magenta-text'>.light-green-bg</td>
                <td className='light-green-bg'>Light Green Background</td>
              </tr>
              <tr>
                <td scope="row" className='magenta-text'>.faint-orange-bg</td>
                <td className='faint-orange-bg'>Faint Orange Background</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default ColorsOverview;