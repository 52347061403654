import './_form-elements.scss';
import React from 'react';
import { CopyBlock, monoBlue } from "react-code-blocks";

function FormElements() {
  return (
    <>
      <h1>Form Elements</h1>
      <p>When creating forms, we use the <span className='magenta-text'>.form-element-container</span> class on the containing div or component to use
        custom form classes as well as space out form elements from one another. The class can be done as a standalone div or as a class added to the div where you would have column classes from Bootstrap.</p>
      <div className='copy-block-div mb-6'>
        <p className='semibold-text mb-0'>Code</p>
        <CopyBlock
          language="html"
          text={`<div class="form-element-container">
    ...
</div>`}
          theme={monoBlue}
          showLineNumbers={false}
          codeBlock
        />
      </div>
      <h2>Text Box Input</h2>
      <p>The basic text box input is no different than any other occasion. Please try to use proper code with labels, and add the <span className='magenta-text'>.form-control</span> class to your input field so brand styling will be applied.</p>
      <p>The fields are desgned to fill the container space, so be sure to use Bootstrap column classes to set widths on these fields.</p>
      <div className='container-fluid mb-4'>
        <div className='row'>
          <div className='col form-element-container'>
            <label htmlFor='textBoxExample01'>Text Box Example</label>
            <input
              type='text'
              className='form-control'
              id='textBoxExample01'
              placeholder='Container takes up full width'
            />
          </div>
        </div>
        <div className='row'>
          <div className='col col-md-6 col-xxxl-5 form-element-container'>
            <label htmlFor='textBoxExample02'>Text Box Example</label>
            <input
              type='text'
              className='form-control'
              id='textBoxExample02'
              placeholder='Container takes up partial width'
            />
          </div>
        </div>
      </div>

      <div className='copy-block-div mb-5'>
        <p className='semibold-text mb-0'>Code</p>
        <CopyBlock
          language="html"
          text={`<div class="form-element-container">
    <label for="textBoxExample">Text Box Example</label>
    <input type="text" class="form-control" id="textBoxExample" placeholder="Placeholder example">
</div>`}
          theme={monoBlue}
          showLineNumbers={false}
          codeBlock
        />
      </div>
      <h3>Inputs with Buttons</h3>
      <p>We utilize input fields with buttons often in the dashboard, either placing text or a FontAwesome icon in the button. For our purposes, these are ideal to mock up datepickers, file browse/upload fields, or even contract/agent searches.</p>
      <div className='container-fluid mb-4'>
        <div className='row'>
          <div className='col col-md-6 col-xl-6 col-xxxl-5 form-element-container'>
            <label htmlFor='fileUploadExample'>File Upload Mockup Example</label>
            <div className='input-group'>
              <input
                type='text'
                className='form-control'
                id='fileUploadExample'
                placeholder=''
                aria-label='File Upload Mockup Example'
                aria-describedby='button-addon01'
              />
              <button className='btn btn-default' type='button' id='button-addon01'>
                Browse
              </button>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col col-md-6 col-xl-6 col-xxxl-5 form-element-container'>
            <label htmlFor='searchFieldExample'>Search Field Example</label>
            <div className='input-group'>
              <input
                type='text'
                className='form-control'
                id='searchFieldExample'
                placeholder=''
                aria-label='Search Field Example'
                aria-describedby='button-addon02'
              />
              <button className='btn btn-info' type='button' id='button-addon02'>
                <i className='fa-solid fa-magnifying-glass' />
              </button>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col col-md-6 col-xl-6 col-xxxl-5 form-element-container'>
            <label htmlFor='datepickerMockupExample'>Datepicker Mockup Example</label>
            <div className='input-group'>
              <input
                type='text'
                className='form-control'
                id='datepickerMockupExample'
                placeholder=''
                aria-label='Datepicker Mockup Example'
                aria-describedby='button-addon03'
                defaultValue='MM/DD/YYYY'
              />
              <button className='btn btn-default' type='button' id='button-addon03'>
                <i className='fa-solid fa-calendar-days' />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='copy-block-div mb-5'>
        <p className='semibold-text mb-0'>Code</p>
        <CopyBlock
          language="html"
          text={`<div class="form-element-container">
    <label for="fileUploadExample">File Upload Mockup Example</label>
    <div class="input-group">
        <input type="text" id="fileUploadExample" class="form-control" aria-label="File Upload Mockup Example" aria-describedby="button-addon01">
        <div class="input-group-append">
            <button class="btn btn-default" type="button" id="button-addon01">Browse</button>
        </div>
    </div>
</div>

<div class="form-element-container">
    <label for="searchFieldExample">Search Field Example</label>
    <div class="input-group">
        <input type="text" id="searchFieldExample" class="form-control" aria-label="Search Field Example" aria-describedby="button-addon02">
        <div class="input-group-append">
            <button class="btn btn-info" type="button" id="button-addon02"><i class="fa-solid fa-magnifying-glass"></i></button>
        </div>
    </div>
</div>

<div class="form-element-container">
    <label for="datepickerMockupExample">Datepicker Mockup Example</label>
    <div class="input-group">
        <input type="text" id="datepickerMockupExample" class="form-control" aria-label="Datepicker Mockup Example" aria-describedby="button-addon03">
        <div class="input-group-append">
            <button class="btn btn-default" type="button" id="button-addon03"><i class="fa-solid fa-calendar-days"></i></button>
        </div>
    </div>
</div>`}
          theme={monoBlue}
          showLineNumbers={false}
          codeBlock
        />
      </div>
      <p className='mb-6'>When attaching buttons, use the class <span className='magenta-text'>.btn-default</span> to have the button in a slightly darker gray from the field. Use the class <span className='magenta-text'>.btn-info</span> to color the button completely in match with the field.</p>
      <h3>Inputs with Text Attached</h3>
      <p>You can also append or prepend text or icons to form fields as needed. It's quite handy for instances such as a dollar sign, or a unit of measure.</p>
      <div className='container-fluid mb-4'>
        <div className='row'>
          <div className='col col-md-6 col-xl-6 col-xxxl-5 form-element-container'>
            <label htmlFor='uomExample'>Unit of Measure Example</label>
            <div className='input-group'>
              <input
                type='text'
                className='form-control'
                placeholder=''
                id='uomExample'
                aria-label='Unit of Measure Example'
                aria-describedby='basic-addon01'
                defaultValue={1234}
              />
              <span className='input-group-text half-blue' id='basic-addon01'>
                kWh
              </span>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col col-md-6 col-xl-6 col-xxxl-5 form-element-container'>
            <label htmlFor='iconLabelExample'>Icon Label Example</label>
            <div className='input-group'>
              <span className='input-group-text half-blue' id='basic-addon02'>
                <i className='fa-solid fa-dollar-sign' />
              </span>
              <input
                type='text'
                className='form-control'
                placeholder=''
                id='iconLabelExample'
                aria-label='Unit of Measure Example'
                aria-describedby='basic-addon02'
                defaultValue='123.45'
              />
            </div>
          </div>
        </div>
      </div>

      <div className='copy-block-div mb-5'>
        <p className='semibold-text mb-0'>Code</p>
        <CopyBlock
          language="html"
          text={`<div class="form-element-container">
    <label for="uomExample">Unit of Measure Example</label>
    <div class="input-group">
        <input type="text" id="uomExample" class="form-control" aria-label="Unit of Measure Example" aria-describedby="basic-addon01">
        <div class="input-group-append">
            <span class="input-group-text half-blue" id="basic-addon01">kWh</span>
        </div>
    </div>
</div>

<div class="form-element-container">
    <label for="iconLabelExample">Icon Label Example</label>
    <div class="input-group">
        <div class="input-group-prepend">
            <span class="input-group-text half-blue" id="basic-addon02"><i class="fa-solid fa-dollar-sign"></i></span>
        </div>
        <input type="text" id="iconLabelExample" class="form-control" aria-label="Icon Label Example" aria-describedby="basic-addon02">
    </div>
</div>`}
          theme={monoBlue}
          showLineNumbers={false}
          codeBlock
        />
      </div>
      <p className='mb-6'>If you wish for your text to carry the same color scheme as the form field, be sure to add the <span className='magenta-text'>.half-blue</span> class to your <span className='magenta-text'>.input-group-text</span> span, or else the addition will contain a gray color similar to the default button class.</p>
      <h2>Text Area</h2>
      <p>Text Areas can also utilize the same <span className='magenta-text'>.form-control</span> class for styling.</p>
      <div className='container-fluid mb-4'>
        <div className='row'>
          <div className='col form-element-container'>
            <label htmlFor='textAreaExample'>Text Area Example</label>
            <textarea
              className='form-control'
              id='textAreaExample'
              rows={6}
              defaultValue={
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla non vehicula ipsum, eu elementum nibh. Fusce a nulla eros. Nullam fermentum augue non lectus hendrerit egestas. In dignissim, felis nec tincidunt porttitor, mauris tortor tristique tellus, vel convallis justo sem in elit. Integer tempus lacus lectus, ut placerat odio fringilla in. Donec gravida scelerisque enim et vehicula. Nam id augue eu massa tristique convallis. Donec et facilisis neque. Duis elementum nunc eu erat molestie ullamcorper. Proin in libero sodales, iaculis felis sed, convallis neque. Nulla id gravida massa. Suspendisse potenti.'
              }
            />
          </div>
        </div>
      </div>
      <div className='copy-block-div mb-6'>
        <p className='semibold-text mb-0'>Code</p>
        <CopyBlock
          language="html"
          text={`<div class="form-element-container">
    <label for="textAreaExample">Text Box Example</label>
    <textarea class="form-control" id="textAreaExample" rows="6"></textarea>
</div>`}
          theme={monoBlue}
          showLineNumbers={false}
          codeBlock
        />
      </div>
      <h2>Radio Buttons</h2>
      <p>We utilize special styling for radio buttons.</p>
      <div className='container-fluid mb-4'>
        <div className='row'>
          <div className='col form-element-container'>
            <label htmlFor='radioButtonExample' className='semibold-text'>
              Radio Button Example
            </label>
            <div className='form-check'>
              <input
                className='form-check-input'
                type='radio'
                name='radioButtonExample'
                id='customRadio01'
                defaultChecked=''
              />
              <label className='form-check-label' htmlFor='customRadio01'>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </label>
            </div>
            <div className='form-check'>
              <input
                className='form-check-input'
                type='radio'
                name='radioButtonExample'
                id='customRadio02'
              />
              <label className='form-check-label' htmlFor='customRadio02'>
                Aliquam id urna euismod, vulputate nisl at, blandit ipsum.
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className='copy-block-div mb-6'>
        <p className='semibold-text mb-0'>Code</p>
        <CopyBlock
          language="html"
          text={`<div class="form-element-container">
    <label for="radioButtonExample">Radio Button Example</label>
    <div class="custom-control custom-radio">
        <input type="radio" id="customRadio01" name="radioButtonExample" class="custom-control-input" checked>
        <label class="custom-control-label" for="customRadio01">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</label>
    </div>
    <div class="custom-control custom-radio">
        <input type="radio" id="customRadio02" name="radioButtonExample" class="custom-control-input">
        <label class="custom-control-label" for="customRadio02">Aliquam id urna euismod, vulputate nisl at, blandit ipsum.</label>
    </div>
</div>`}
          theme={monoBlue}
          showLineNumbers={false}
          codeBlock
        />
      </div>
      <h2>Checkboxes</h2>
      <p>We utilize special styling for checkboxes.</p>
      <div className='container-fluid mb-4'>
        <div className='row'>
          <div className='col form-element-container'>
            <label htmlFor='checkboxExample' className='semibold-text'>
              Checkbox Example
            </label>
            <div className='form-check'>
              <input
                className='form-check-input'
                type='checkbox'
                defaultValue='checkbox01'
                id='checkboxExample01'
                defaultChecked=''
              />
              <label className='form-check-label' htmlFor='checkboxExample01'>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </label>
            </div>
            <div className='form-check'>
              <input
                className='form-check-input'
                type='checkbox'
                defaultValue='checkbox02'
                id='checkboxExample02'
              />
              <label className='form-check-label' htmlFor='checkboxExample02'>
                Aliquam id urna euismod, vulputate nisl at, blandit ipsum.
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className='copy-block-div mb-6'>
        <p className='semibold-text mb-0'>Code</p>
        <CopyBlock
          language="html"
          text={`<div class="form-element-container">
    <label for="checkboxExample">Checkbox Example</label>
    <div class="custom-control custom-checkbox">
        <input type="checkbox" class="custom-control-input" id="checkboxExample01" checked>
        <label class="custom-control-label" for="checkboxExample01">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</label>
    </div>
    <div class="custom-control custom-checkbox">
        <input type="checkbox" class="custom-control-input" id="checkboxExample02">
        <label class="custom-control-label" for="checkboxExample02">Aliquam id urna euismod, vulputate nisl at, blandit ipsum.</label>
    </div>
</div>`}
          theme={monoBlue}
          showLineNumbers={false}
          codeBlock
        />
      </div>
      <h2>Switches</h2>
      <p>Switches are basically checkboxes utilizing some CSS magic to make them look like a switch you can flip. They are recommended for when you need a single checkbox that will be used as an on/off. They are not recommended for situations where a user would be selecting multiple items in a form submission.</p>
      <div className='container-fluid mb-4'>
        <div className='row'>
          <div className='col form-element-container'>
            <div className='form-check form-switch'>
              <input className='form-check-input' type="checkbox" role="switch" id="basicSwitchExample" />
              <label className='form-check-label' for="basicSwitchExample">Donec vel turpis</label>
            </div>
          </div>
        </div>
      </div>
      <div className='copy-block-div mb-5'>
        <p className='semibold-text mb-0'>Code</p>
        <CopyBlock
          language="html"
          text={`<div class="form-element-container">
    <div class="custom-control custom-switch">
        <input type="checkbox" class="custom-control-input" id="basicSwitchExample">
        <label class="custom-control-label custom-switch-label" for="basicSwitchExample">Donec vel turpis</label>
    </div>
</div>`}
          theme={monoBlue}
          showLineNumbers={false}
          codeBlock
        />
      </div>
      <p>The text in the label is generally set in stone, but we also have two custom switches that change their content between <em>"Off"</em> and <em>"On"</em>, or <em>"Active"</em> and <em>"Inactive"</em>.  Just use <span className='magenta-text'>&lt;span&gt;&lt;/span&gt;</span> in place of the text, and add the <span className='magenta-text'>.custom-switch-onOff</span> class or <span className='magenta-text'>.custom-switch-active</span> class to the label.</p>
      <div className='container-fluid mb-4'>
        <div className='row'>
          <div className='col form-element-container'>
            <div className='form-check form-switch'>
              <input
                className='form-check-input'
                type='checkbox'
                role='switch'
                id='onOffSwitchExample'
              />
              <label
                className='form-check-label form-switch-onOff'
                htmlFor='onOffSwitchExample'
              >
                <span />
              </label>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col form-element-container'>
            <div className='form-check form-switch'>
              <input
                className='form-check-input'
                type='checkbox'
                role='switch'
                id='activeSwitchExample'
              />
              <label
                className='form-check-label form-switch-active'
                htmlFor='activeSwitchExample'
              >
                <span />
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className='copy-block-div mb-6'>
        <p className='semibold-text mb-0'>Code</p>
        <CopyBlock
          language="html"
          text={`<div class="form-element-container">
    <div class="custom-control custom-switch">
        <input type="checkbox" class="custom-control-input" id="onOffSwitchExample">
        <label class="custom-control-label custom-switch-label custom-switch-onOff" for="onOffSwitchExample"><span></span></label>
    </div>
</div>

<div class="form-element-container">
    <div class="custom-control custom-switch">
        <input type="checkbox" class="custom-control-input" id="activeSwitchExample">
        <label class="custom-control-label custom-switch-label custom-switch-active" for="activeSwitchExample"><span></span></label>
    </div>
</div>`}
          theme={monoBlue}
          showLineNumbers={false}
          codeBlock
        />
      </div>
      <h2>Select Dropdowns</h2>
      <p className='mb-6'>For any and all select dropdowns, we're not using the traditional <span className='magenta-text'>&lt;select&gt;</span> setups known to HTML, but special stylized dropdown components. Please see <a href="/components/dropdowns">Dropdowns</a> for more details.</p>
      <h2>Helper Text</h2>
      <p>Beyond just form fields and elements, you can also drop a smaller size text below any field simply by adding the <span className='magenta-text'>.helper-text</span> class to a paragraph. This text comes in handy for any special instructions you wish to add to a field.</p>
      <div className='container-fluid mb-4'>
        <div className='row'>
          <div className='col col-md-6 col-xxxl-5 form-element-container'>
            <label htmlFor='textBoxExample03'>Text Box Example</label>
            <input
              type='text'
              className='form-control'
              id='textBoxExample03'
              placeholder=''
            />
            <p className='helper-text'>Here are some additional instructions.</p>
          </div>
        </div>
      </div>

      <div className='copy-block-div mb-5'>
        <p className='semibold-text mb-0'>Code</p>
        <CopyBlock
          language="html"
          text={`<div class="form-element-container">
    <label for="textBoxExample">Text Box Example</label>
    <input type="text" class="form-control" id="textBoxExample" placeholder="Placeholder example">
    <p class="helper-text">Here are some additional instructions.</p>
</div>`}
          theme={monoBlue}
          showLineNumbers={false}
          codeBlock
        />
      </div>
      <h2>Required Fields</h2>
      <p>Any field can be visually shown as required by adding the <span className='magenta-text'>.required</span> class to its label. This will not set up requirements or validation, but simply visally add a red asterik to the label.</p>
      <div className='container-fluid mb-4'>
        <div className='row'>
          <div className='col col-md-6 col-xxxl-5 form-element-container'>
            <label htmlFor='textBoxExample04' className='required'>Text Box Example</label>
            <input
              type='text'
              className='form-control'
              id='textBoxExample04'
              placeholder=''
            />
          </div>
        </div>
      </div>
      <div className='copy-block-div mb-6'>
        <p className='semibold-text mb-0'>Code</p>
        <CopyBlock
          language="html"
          text={`<div class="form-element-container">
    <label for="textBoxExample" class="required">Text Box Example</label>
    <input type="text" class="form-control" id="textBoxExample" placeholder="Placeholder example">
</div>`}
          theme={monoBlue}
          showLineNumbers={false}
          codeBlock
        />
      </div>
      <h2>Validation</h2>
      <p>You can set any element to an invalid state by adding the <span className='magenta-text'>.invalid</span> class to the div containing. <span className='magenta-text'>.form-element-container</span>. You can also add a message below the field with the <span className='magenta-text'>.invalid-feedback</span> class on a paragraph similar to helper text.</p>
      <div className='container-fluid mb-4'>
        <div className='row'>
          <div className='col col-md-6 col-xxxl-5 form-element-container invalid'>
            <label htmlFor='textBoxExample05'>Text Box Example</label>
            <input
              type='text'
              className='form-control'
              id='textBoxExample05'
              placeholder=''
            />
            <p className='invalid-feedback'>This is an invalid field.</p>
          </div>
        </div>
      </div>
      <div className='copy-block-div mb-6'>
        <p className='semibold-text mb-0'>Code</p>
        <CopyBlock
          language="html"
          text={`<div class="form-element-container invalid">
    <label for="textBoxExample">Text Box Example</label>
    <input type="text" class="form-control" id="textBoxExample" placeholder="Placeholder example">
    <p class="invalid-feedback">This is an invalid field.</p>
</div>`}
          theme={monoBlue}
          showLineNumbers={false}
          codeBlock
        />
      </div>

    </>
  );
}

export default FormElements;