import './_tables.scss';
import React from 'react';
import { CopyBlock, monoBlue } from "react-code-blocks";

import {
  DataGrid,
  Column,
  HeaderFilter
} from 'devextreme-react/data-grid';
import { brokerPayments } from './brokerPayments';

function Tables() {
  return (
    <>
      <h1>Tables</h1>
      <p className='mb-6'>With the large amounts of data being shown to a user at any given time, it's inevitable you'll be using tables to display this data. We have several options.</p>
      <h2>Tables VS Cards</h2>
      <p>Before we start, it's important to drive the idea that we should not always use a table unless absolutely necessary. A page full of tables and datagrids can become visual noise on the user, and it is encouraged to try to use <a href="https://getbootstrap.com/docs/4.5/components/card/" target="_blank" rel="noreferrer">card-based layouts</a> if you're dealing with small amounts of information.</p>
      <p className='mb-6'>It is also encouraged to refrain from putting forms into tables as a means to create repeated groups of form elements. A suggested would be our <a href="/components/content-div">form group component</a>.</p>
      <h2>HTML Tables</h2>
      <p className='mb-4'>Simple HTML tables are ideal if you simply need to display a lot of information and do not need any additional functionality. If you do need additional, please see the DevExtreme Datagrid below.</p>
      <table className="table mb-4">
        <thead>
          <tr>
            <th scope="col">Broker</th>
            <th scope="col">State</th>
            <th scope="col">Forecasted</th>
            <th scope="col">Actuals</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td scope="row">Michael Rogers</td>
            <td>Illinois</td>
            <td>$1,234.56</td>
            <td>$1,123.45</td>
          </tr>
          <tr>
            <td scope="row">Carlos Miller</td>
            <td>Indiana</td>
            <td>$789.01</td>
            <td>$778.90</td>
          </tr>
          <tr>
            <td scope="row">Nicole Edwards</td>
            <td>Texas</td>
            <td>$2,345.67</td>
            <td>$2,123.45</td>
          </tr>
          <tr>
            <td scope="row">Steven Davis</td>
            <td>New York</td>
            <td>$1,567.89</td>
            <td>$1,564.56</td>
          </tr>
        </tbody>
      </table>
      <p className='mb-6'>All our tables are based on what is included in <a href="https://getbootstrap.com/docs/4.5/content/tables/" target="_block">Bootstrap's table classes</a>, but we've added in a few more CSS classes to customize things to our needs.</p>
      <h3>Minimum Width on Columns</h3>
      <p className='mb-4'>These three classes are great if you need to stretch or space out a column.  Simply add one of these CSS classes to at least one of your cells and it will push the column width. </p>
      <p><span className='magenta-text'>.min-width-15</span> sets the minimum width to 15rem.</p>
      <table className="table table-bordered mb-4">
        <thead>
          <tr>
            <th scope="col">Broker</th>
            <th scope="col">Date</th>
            <th scope="col">Forecasted</th>
            <th scope="col">Actuals</th>
            <th scope="col">Payment</th>
            <th scope="col">Deviation</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td scope="row" className='min-width-15'>John Jacobson</td>
            <td>MM/DD/YYYY</td>
            <td>$132.45</td>
            <td>$123.54</td>
            <td>$112.34</td>
            <td>-$11.20</td>
          </tr>
        </tbody>
      </table>
      <p><span className='magenta-text'>.min-width-20</span> sets the minimum width to 20rem.</p>
      <table className="table table-bordered mb-4">
        <thead>
          <tr>
            <th scope="col">Broker</th>
            <th scope="col">Date</th>
            <th scope="col">Forecasted</th>
            <th scope="col">Actuals</th>
            <th scope="col">Payment</th>
            <th scope="col">Deviation</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td scope="row" className='min-width-20'>John Jacobson</td>
            <td>MM/DD/YYYY</td>
            <td>$132.45</td>
            <td>$123.54</td>
            <td>$112.34</td>
            <td>-$11.20</td>
          </tr>
        </tbody>
      </table>
      <p><span className='magenta-text'>.min-width-25</span> sets the minimum width to 25rem.</p>
      <table className="table table-bordered mb-6">
        <thead>
          <tr>
            <th scope="col">Broker</th>
            <th scope="col">Date</th>
            <th scope="col">Forecasted</th>
            <th scope="col">Actuals</th>
            <th scope="col">Payment</th>
            <th scope="col">Deviation</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td scope="row" className='min-width-25'>John Jacobson</td>
            <td>MM/DD/YYYY</td>
            <td>$132.45</td>
            <td>$123.54</td>
            <td>$112.34</td>
            <td>-$11.20</td>
          </tr>
        </tbody>
      </table>
      <h3>Smaller Table</h3>
      <p className='mb-4'>Add <span className='magenta-text'>.table-sm</span> to the main <span className='magenta-text'>&lt;table&gt;</span> tag and it will shrink down the padding in all the cells to make your table more slim and compact.</p>
      <table className="table table-bordered table-sm mb-6">
        <thead>
          <tr>
            <th scope="col">Broker</th>
            <th scope="col">State</th>
            <th scope="col">Forecasted</th>
            <th scope="col">Actuals</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td scope="row">Michael Rogers</td>
            <td>Illinois</td>
            <td>$1,234.56</td>
            <td>$1,123.45</td>
          </tr>
          <tr>
            <td scope="row">Carlos Miller</td>
            <td>Indiana</td>
            <td>$789.01</td>
            <td>$778.90</td>
          </tr>
          <tr>
            <td scope="row">Nicole Edwards</td>
            <td>Texas</td>
            <td>$2,345.67</td>
            <td>$2,123.45</td>
          </tr>
          <tr>
            <td scope="row">Steven Davis</td>
            <td>New York</td>
            <td>$1,567.89</td>
            <td>$1,564.56</td>
          </tr>
        </tbody>
      </table>
      <h3>Colored Tables</h3>
      <p className='mb-4'>You can color the background of an entire table gray by adding the <span className='magenta-text'>.table-gray</span> class to the main <span className='magenta-text'>&lt;table&gt;</span> tag.</p>
      <div className='container-fluid mb-5'>
        <div className='row'>
          <div className='col pt-4 pb-2'>
            <table className="table table-bordered table-gray">
              <thead>
                <tr>
                  <th scope="col">Broker</th>
                  <th scope="col">Date</th>
                  <th scope="col">Forecasted</th>
                  <th scope="col">Actuals</th>
                  <th scope="col">Payment</th>
                  <th scope="col">Deviation</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td scope="row" className='min-width-15'>John Jacobson</td>
                  <td>MM/DD/YYYY</td>
                  <td>$132.45</td>
                  <td>$123.54</td>
                  <td>$112.34</td>
                  <td>-$11.20</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <p className='mb-4'>Likewise, you can color the background of an entire table white by adding the <span className='magenta-text'>.table-white</span> class to the main <span className='magenta-text'>&lt;table&gt;</span> tag. This is ideal for when you need the table white in a space that has a background.</p>
      <div className='container-fluid mb-6 outline-gray-bg'>
        <div className='row'>
          <div className='col pt-4 pb-2'>
            <table className="table table-bordered table-white">
              <thead>
                <tr>
                  <th scope="col">Broker</th>
                  <th scope="col">Date</th>
                  <th scope="col">Forecasted</th>
                  <th scope="col">Actuals</th>
                  <th scope="col">Payment</th>
                  <th scope="col">Deviation</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td scope="row" className='min-width-15'>John Jacobson</td>
                  <td>MM/DD/YYYY</td>
                  <td>$132.45</td>
                  <td>$123.54</td>
                  <td>$112.34</td>
                  <td>-$11.20</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <h3>Removing Borders</h3>
      <p>The <span className='magenta-text'>.table-no-outer-border</span> class is a special styling for tables used in our SaaS product and similar layouts.
        You add it to the <span className='magenta-text'>&lt;table&gt;</span> tag to create these final results. The class will also add extra padding on the bottom
        and center all content in their cells, but you can still apply classes such as <span className='magenta-text'>.text-left</span> to change content to the left, as you can see with the broker column.</p>
      <table className="table table-bordered table-no-outer-border mb-5">
        <thead>
          <tr>
            <th scope="col" className='text-start'>Broker</th>
            <th scope="col">State</th>
            <th scope="col">Forecasted</th>
            <th scope="col">Actuals</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td scope="row" className='text-start'>Michael Rogers</td>
            <td>Illinois</td>
            <td>$1,234.56</td>
            <td>$1,123.45</td>
          </tr>
          <tr>
            <td scope="row" className='text-start'>Carlos Miller</td>
            <td>Indiana</td>
            <td>$789.01</td>
            <td>$778.90</td>
          </tr>
          <tr>
            <td scope="row" className='text-start'>Nicole Edwards</td>
            <td>Texas</td>
            <td>$2,345.67</td>
            <td>$2,123.45</td>
          </tr>
          <tr>
            <td scope="row" className='text-start'>Steven Davis</td>
            <td>New York</td>
            <td>$1,567.89</td>
            <td>$1,564.56</td>
          </tr>
        </tbody>
      </table>
      <p>Additionally, you can also apply the <span className='magenta-text'>.no-btm-border</span> class to any cell or header cell to remove it's bottom border, as you can see used in the heading row to double it and make bigger cells.</p>
      <p>You can also use the <span className='magenta-text'>.no-vert-borders</span> class to any regular cell to remove the top and bottom borders, as you can see in the Date column.</p>
      <table className="table table-no-outer-border table-sm mb-6">
        <thead>
          <tr>
            <th scope="col" className='no-btm-border py-0'></th>
            <th scope="col" className='no-btm-border font-size-075 pb-0'>Estimated</th>
            <th scope="col" className='no-btm-border font-size-075 pb-0'>Calculated</th>
          </tr>
          <tr>
            <th scope="col">Date</th>
            <th scope="col">Forecasted</th>
            <th scope="col">Actuals</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td scope="row" className='no-vert-borders'>MM/DD/YYYY</td>
            <td>$1,234.56</td>
            <td>$1,123.45</td>
          </tr>
          <tr>
            <td scope="row" className='no-vert-borders'>MM/DD/YYYY</td>
            <td>$789.01</td>
            <td>$778.90</td>
          </tr>
          <tr>
            <td scope="row" className='no-vert-borders'>MM/DD/YYYY</td>
            <td>$2,345.67</td>
            <td>$2,123.45</td>
          </tr>
          <tr>
            <td scope="row" className='no-vert-borders'>MM/DD/YYYY</td>
            <td>$1,567.89</td>
            <td>$1,564.56</td>
          </tr>
          <tr>
            <td scope="row" className='no-vert-borders'>MM/DD/YYYY</td>
            <td>$1,234.56</td>
            <td>$1,123.45</td>
          </tr>
          <tr>
            <td scope="row" className='no-vert-borders'>MM/DD/YYYY</td>
            <td>$789.01</td>
            <td>$778.90</td>
          </tr>
          <tr>
            <td scope="row" className='no-vert-borders'>MM/DD/YYYY</td>
            <td>$2,345.67</td>
            <td>$2,123.45</td>
          </tr>
          <tr>
            <td scope="row" className='no-vert-borders'>MM/DD/YYYY</td>
            <td>$1,567.89</td>
            <td>$1,564.56</td>
          </tr>
        </tbody>
      </table>
      <h3>Remove All Wrapping</h3>
      <p className='mb-6'>To save time and coding, the <span className='magenta-text'>.table-nowrap</span> class can be applied to the <span className='magenta-text'>&lt;table&gt;</span> tag to add <span className='magenta-text'>white-space: nowrap;</span> to
        every single cell of a table. This will save you having to apply the Bootstrap class <span className='magenta-text'>.text-nowrap</span> to every single cell individually.</p>
      <h2>DevExtreme Datagrids</h2>
      <p>If you need a table with a more robust functionality, consider a <a href="https://js.devexpress.com/Overview/DataGrid/" target="_blank" rel="noreferrer">Datagrid from DevExtreme</a>. You can go beyond the typical HTML table with features like sorting, filtering, and even parent-child tables just to name a few.</p>
      <p>Please look over their documentation on setup and usage, and if you use the <span className='magenta-text'>cellTemplate</span> feature to put custom HTML in a cell, please wrap anything you do in a div with the <span className='magenta-text'>.chart-cell</span> class put on it.</p>
      <div className='mb-6'>
        <DataGrid
          id="dataGrid"
          dataSource={brokerPayments}
          keyExpr="ID"
          showBorders="true">
          <HeaderFilter visible={true} />
          <Column dataField="Broker"></Column>
          <Column
            dataField="State"
            allowHeaderFiltering={false}></Column>
          <Column
            dataField="Forecasted"
            allowHeaderFiltering={false}
            format="$,##0.00"></Column>
          <Column
            dataField="Actuals"
            allowHeaderFiltering={false}
            format="$,##0.00"></Column>
        </DataGrid>
      </div>
      <h2>Table Controls</h2>
      <p>Whether you use a simple HTML table or a DevExtreme DataGrid, this button setup can help if you wish to add batch controls or other controls to your table:</p>
      <div className='container-fluid mb-5'>
        <div className='row d-flex mx-0 table-controls'>
          <div className='col-auto table-control-link'>
            <a href="#"><i className='fa-regular fa-square-plus'></i><span>New Entry</span></a>
          </div>
          <div className='col-auto table-control-link'>
            <a href="#"><i className='fa-solid fa-user-check'></i><span>Assign</span></a>
          </div>
          <div className='col-auto table-control-link'>
            <a href="#" className='disabled'><i className='fa-solid fa-share-from-square'></i><span>Archive</span></a>
          </div>
          <div className='col-auto table-control-link'>
            <a href="#"><i className='fa-solid fa-trash'></i><span>Delete</span></a>
          </div>
          <div className='col-auto me-sm-auto table-control-link'>
            <a href="#"><i className='fa-solid fa-file-excel'></i><span>Export to Excel</span></a>
          </div>
          <div className='col-auto table-control-link'>
            <a href="#"><i className='fa-solid fa-list-ul'></i><span>View Archive</span></a>
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">Broker</th>
                  <th scope="col">Date</th>
                  <th scope="col">Forecasted</th>
                  <th scope="col">Actuals</th>
                  <th scope="col">Payment</th>
                  <th scope="col">Deviation</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td scope="row" className='min-width-15'>John Jacobson</td>
                  <td>MM/DD/YYYY</td>
                  <td>$132.45</td>
                  <td>$123.54</td>
                  <td>$112.34</td>
                  <td>-$11.20</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className='copy-block-div mb-5'>
        <p className='semibold-text mb-0'>Code</p>
        <CopyBlock
          language="html"
          text={`<div class="row d-flex mx-0 table-controls">
    <div class="col-auto table-control-link">
        <a href="#"><i class="fa-regular fa-square-plus"></i><span>New Entry</span></a>
    </div>
    <div class="col-auto table-control-link">
        <a href="#"><i class="fa-solid fa-user-check"></i><span>Assign</span></a>
    </div>
    <div class="col-auto table-control-link">
        <a href="#" class="disabled"><i class="fa-solid fa-share-from-square"></i><span>Archive</span></a>
    </div>
    <div class="col-auto table-control-link">
        <a href="#"><i class="fa-solid fa-trash"></i><span>Delete</span></a>
    </div>
    <div class="col-auto mr-sm-auto table-control-link">
        <a href="#"><i class="fa-solid fa-file-excel"></i><span>Export to Excel</span></a>
    </div>
    <div class="col-auto table-control-link">
        <a href="#"><i class="fa-solid fa-list-ul"></i><span>View Archive</span></a>
    </div>
</div>`}
          theme={monoBlue}
          showLineNumbers={false}
          codeBlock
        />
      </div>
      <p>The final result is a combination of Bootstrap flex and placement classes with our <span className='magenta-text'>.table-controls</span> 
        class on the row. The buttons themselves use the class <span className='magenta-text'>.table-control-link</span> to make them work more like buttons 
        and less like text hyperlinks. When you are in a smaller viewport, the text will vanish and only leave the icons.</p>
        <p>If you wish to float a button to the right side like we have with "View Archive", you just use Bootstrap's <span className='magenta-text'>.mr-auto</span> class 
        <em>(set to only work on all viewports but mobile)</em> on whatever button you wish to be your last one on the left.</p>
    </>
  );
}

export default Tables;