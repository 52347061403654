import './_white-labeling.scss';
import React from 'react';
// import { CopyBlock, monoBlue } from "react-code-blocks";

function WhiteLabeling() {
  return (
    <>
      <div className='row'>
        <div className='col'>
          <h1>White Labeling Emails</h1>
        </div>
      </div>
      <div className='row'>
        <div className='col-12 col-lg-7'>
          <p>White-Labeling emails (and even the dashboard) is an important part of our business model.  Most Parent Brokers pay us a fee not only for the services we provide, but also to provide them a dashboard and email system that will contain their branding and business name.</p>
          <p>When making any emails outside of newsletters*, please be sure to include the headers and footers not only for Power Kiosk, but also the generic ones set up for the brokers. Your final files should just be everything stacked on one another. The development team has a system in place to use the correct ones for the correct occasions.</p>
          <p>Likewise, it&rsquo;s best to try to keep any content white-labeled, which means no mention of Power Kiosk, or put in a variable for the development team to use, like <em>@brokerName@</em>, so their scripting will put in the Parent Broker&rsquo;s name or us.</p>
          <p>If you absolutely need to have content mention Power Kiosk and can&rsquo;t use the variable, then create the content twice and use HTML comments to show which is for Power Kiosk and which is for White Labeling.</p>
          <p className='font-size-075'>* Newsletters are only sent to Parent Brokers, and are mainly about Power Kiosk news and features, so they do not need to be White Labeled.</p>
        </div>
        <div className='col-5 d-none d-lg-block'>
          <img src="/assets/img/white-labeling-image.png" alt="Power Kiosk and White Label Headers and Footers" className='img-fluid' />
        </div>
      </div>
    </>
  );
}

export default WhiteLabeling;