import './_newsletters.scss';
import React from 'react';
// import { CopyBlock, monoBlue } from "react-code-blocks";

function Newsletters() {
  return (
    <>
      <div className='row'>
        <div className='col'>
        <h1>Newsletters</h1>
        </div>
      </div>
      <div className='row'>
        <div className='col-12 col-xl-7 order-1 order-xl-0'>
        <p>A newsletter is a different beast from an alert email. There is a certain level of marketing and push in these emails as we are often highlighting new features and/or news we wish to share with Parent Brokers.</p>
      <p>You should lead off with a hero image similar to what you would use with customer emails and a solid headline either highlighting the email as a whole or the primary item you want to start with.</p>
      <p>These emails can get lengthy. Please try to separate topics by “striping” the email. So maybe the primary item has a white background, then the next one has a light gray background, and then white for the next one, and so on. Also try to keep content simple and concise. Many might hand you a lot of copy, but they should condense. Try to also suggest ways to condense. Also use sub-headlines to lead off these sections.</p>
      <p>You should try to stay with brand colors on these emails, but you&rsquo;re allowed to deviate if you see a good reason to. We will deviate with Thought Leadership article emails to differentiate these from others. We&rsquo;re a bit more flexible here with branding, but most of these newsletter and announcement emails should stick to brand colors most of the time.</p>
      <p>Unless there&rsquo;s variating content, we also will upload a static version of this email to the static server and link to it via the “View online” link next to the pre-header. We don&rsquo;t do this for alerts or customer emails since they do variate in content.</p>
        </div>
        <div className='col-12 col-xl-5 order-0 order-xl-1 mb-4 mb-xl-0'>
          <img src="/assets/img/newsletter-image.jpg" alt="Email on phone" className='img-fluid shadow' />
        </div>
      </div>
    </>
  );
}

export default Newsletters;