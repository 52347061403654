import './_header.scss';

function Header() {
  return (
    <>
      <header className='container-fluid'>
        <div className='row d-flex align-items-center'>
          <div className='col-auto d-block d-lg-none'>
            <a className='white-link no-right-margin navToggle d-lg-none' type="button" data-bs-toggle="offcanvas" data-bs-target="#mobileNav" aria-controls="mobileNav">
              <i className='fa-solid fa-bars'></i>
            </a>
          </div>
          <div className='col-auto col-lg-6 col-xxl-5 col-xxxl-4 offset-xxl-1 offset-xxxl-2 py-2'>
            <a href='/'>
              <img src="/assets/img/power-kiosk-emblem-lightColor.svg" alt="Power Kiosk" className='d-inline d-md-none pk-logo' />
              <img src="/assets/img/power-kiosk-logo-lightColor.svg" alt="Power Kiosk" className='d-none d-md-inline pk-logo' />
            </a>
          </div>
          <div className='col col-lg-6 col-xxl-5 col-xxxl-4 py-2 text-end'>
            <p className='mb-0 semibold-text font-size-125'>Design System</p>
            <p className='mb-0 font-size-075'>Updated: 02/07/2024</p>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;