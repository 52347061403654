import './_home-page.scss';

function HomePage() {
  return (
    <>
      <div className='row'>
        <div className='col mb-2'>
          <h1>Welcome to the Power Kiosk Design System</h1>
          <p>This is a resource for designing and building pages on both the Power Kiosk energy marketplace dashboard and our SaaS system.</p>
        </div>
      </div>
      <div className='row d-flex justify-content-center'>
        <div className='col col-sm-10 col-lg-8 col-xxl-6 mb-5'>
          <img src="/assets/img/main-landing-dashboard.jpg" alt="Power Kiosk Dashboard Home Page" className='img-fluid shadow' />
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <p className='mb-5'>Included on many pages are code you can copy and use.</p>
          <h2>Pattern Library for Design Applications</h2>
          <p className='mb-5'><a href="/assets/pk-pattern-library.zip" className='semibold-text'>Download</a> our full pattern library for making flat layouts of the dashboard in either Figma or Adobe XD.</p>
          <h2>Power Kiosk User Experience Process</h2>
          <p className='mb-5'>Our process as a company on how we handle UX projects from start to finish.  Please <a href="https://powerkiosk.atlassian.net/wiki/spaces/UX/overview" className='semibold-text' target="_blank" rel="noreferrer">visit the page</a> for more information.</p>
          <h2>Interface Standards for Developers</h2>
          <p>There is also a document on our company Confluence involving interface standards when implementing anything with the interface.  Please <a href="https://powerkiosk.atlassian.net/wiki/spaces/DEV/pages/2360770566/Interface+Standards" className='semibold-text' target="_blank" rel="noreferrer">visit the page</a> and review it at your convenience.</p>
        </div>
      </div>
    </>
  );
}

export default HomePage;