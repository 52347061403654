import './_alert-emails.scss';
import React from 'react';
// import { CopyBlock, monoBlue } from "react-code-blocks";

function AlertEmails() {
  return (
    <>
      <div className='row'>
        <div className='col'>
          <h1>Alert &amp; Notification Emails</h1>
        </div>
      </div>
      <div className='row'>
        <div className='col-12 col-xl-7 order-1 order-xl-0'>
          <p>An alert email is a simple design used mainly as it&rsquo;s titled. It&rsquo;s there to alert brokers of anything we need them to know. The rationale behind the simple design is that the brokers do not need to see nice visuals or marketing copy, but cut right to the point and hand them their message.</p>
          <p>You can see the hero is a simple icon graphic using our brand colors. We always try to put in a short but clear headline to tell the user what this email is, and the copy should come right to the point stating what this email is about in the first sentence, then following up with supporting information.</p>
          <p>Please try to refrain from using an email as a web page unless it&rsquo;s absolutely necessary. If you&rsquo;re asked to make an email loaded with way more information than could be normally consumed in an email, push to condense things down to a simple message that leads into a call-to-action that takes the user to the dashboard for the heavy details.</p>
          <p>Make sure to White Label these emails and un-minify any code you hand over to the development team.</p>
        </div>
        <div className='col-12 col-xl-5 order-0 order-xl-1 mb-4 mb-xl-0'>
          <img src="/assets/img/alert-email-image.jpg" alt="Alert email on laptop" className='img-fluid shadow' />
        </div>
      </div>
    </>
  );
}

export default AlertEmails;