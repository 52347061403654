import './_graphs-charts.scss';
import React from 'react';
import CanvasJSReact from './canvasjs.react';
import { CopyBlock, monoBlue } from "react-code-blocks";

// var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

function GraphsCharts() {

  const chart01 = {
    animationEnabled: true,
    toolTip: {
      shared: true,
    },
    axisY: {
      minimum: 0,
      includeZero: true,
      gridDashType: "longDash",
      gridThickness: 1,
      valueFormatString: "##¢",
      labelFontFamily: "Montserrat",
      labelFontSize: 14,
      titleFontFamily: "Montserrat",
      titleFontSize: 14,
      titleFontWeight: 600,
      stripLines: [{
        value: 17.4,
        label: "100% Fixed",
        labelFontColor: "#1a2574",
        labelFontFamily: "Montserrat",
        labelFontSize: 12,
        thickness: 2,
        color: "#1a2574"
      }]
    },
    axisX: {
      labelAngle: 50,
      interval: 6,
      intervalType: "month",
      valueFormatString: "MMM YYYY",
      labelFontFamily: "Montserrat",
      labelFontSize: 14,
      titleFontFamily: "Montserrat",
      titleFontSize: 14,
      titleFontWeight: 600
    },
    legend: {
      fontFamily: "Montserrat",
      fontSize: 14,
      fontWeight: 600
    },
    data: [{
      type: "spline",
      showInLegend: true,
      markerSize: "0",
      name: "Layered Purchasing",
      yValueFormatString: "##¢",
      xValueFormatString: "MMM YYYY",
      lineColor: "#1e91d6",
      color: "#1e91d6",
      dataPoints: [
        { x: new Date(2022, 6), y: 5 },
        { x: new Date(2023, 1), y: 4 },
        { x: new Date(2023, 6), y: 6 },
        { x: new Date(2024, 1), y: 7 },
        { x: new Date(2024, 6), y: 6 },
        { x: new Date(2025, 1), y: 8 },
        { x: new Date(2025, 6), y: 12 },
        { x: new Date(2026, 1), y: 15 },
        { x: new Date(2026, 6), y: 19 },
        { x: new Date(2027, 1), y: 16 },
        { x: new Date(2027, 5), y: 13 }
      ]
    },
    {
      type: "spline",
      showInLegend: true,
      markerSize: "0",
      yValueFormatString: "##¢",
      xValueFormatString: "MMM YYYY",
      name: "100% Floating",
      lineColor: "#fc8f3b",
      color: "#fc8f3b",
      dataPoints: [
        { x: new Date(2022, 6), y: 4 },
        { x: new Date(2023, 1), y: 5 },
        { x: new Date(2023, 6), y: 4 },
        { x: new Date(2024, 1), y: 8 },
        { x: new Date(2024, 6), y: 9 },
        { x: new Date(2025, 1), y: 7 },
        { x: new Date(2025, 6), y: 13 },
        { x: new Date(2026, 1), y: 12 },
        { x: new Date(2026, 6), y: 16 },
        { x: new Date(2027, 1), y: 12 },
        { x: new Date(2027, 5), y: 10 }
      ]
    }]
  };

  const chart02 = {
    animationEnabled: true,
    toolTip: {
      shared: true,
    },
    axisY: {
      includeZero: false,
      valueFormatString: "$#,##0",
      labelFontFamily: "Montserrat",
      labelFontSize: 14,
      titleFontFamily: "Montserrat",
      titleFontSize: 14,
      titleFontWeight: 600
    },
    axisX: {
      labelFontFamily: "Montserrat",
      labelFontSize: 14,
      titleFontFamily: "Montserrat",
      titleFontSize: 14,
      titleFontWeight: 600
    },
    legend: {
      fontFamily: "Montserrat",
      fontSize: 14,
      fontWeight: 600
    },
    data: [{
      type: "splineArea",
      showInLegend: true,
      markerSize: "0",
      fillOpacity: .3,
      name: "Electricity",
      yValueFormatString: "$#,##0",
      xValueFormatString: "MMM YYYY",
      color: "#1E91D6",
      dataPoints: [
        { x: new Date(2020, 12), y: 30000 },
        { x: new Date(2021, 1), y: 35000 },
        { x: new Date(2021, 2), y: 30000 },
        { x: new Date(2021, 3), y: 30400 },
        { x: new Date(2021, 4), y: 20900 },
        { x: new Date(2021, 5), y: 31000 },
        { x: new Date(2021, 6), y: 30200 },
        { x: new Date(2021, 7), y: 30000 },
        { x: new Date(2021, 8), y: 33000 },
        { x: new Date(2021, 9), y: 38000 },
        { x: new Date(2021, 10), y: 38900 },
        { x: new Date(2021, 11), y: 39000 }
      ]
    },
    {
      type: "splineArea",
      showInLegend: true,
      markerSize: "0",
      fillOpacity: .6,
      yValueFormatString: "$#,##0",
      name: "Natural Gas",
      color: "#FC8F3B",
      dataPoints: [
        { x: new Date(2020, 12), y: 17000 },
        { x: new Date(2021, 1), y: 26000 },
        { x: new Date(2021, 2), y: 10000 },
        { x: new Date(2021, 3), y: 14000 },
        { x: new Date(2021, 4), y: 9000 },
        { x: new Date(2021, 5), y: 10000 },
        { x: new Date(2021, 6), y: 12000 },
        { x: new Date(2021, 7), y: 50000 },
        { x: new Date(2021, 8), y: 13000 },
        { x: new Date(2021, 9), y: 23000 },
        { x: new Date(2021, 10), y: 28000 },
        { x: new Date(2021, 11), y: 13000 }
      ]
    }]
  };

  return (
    <>
      <h1>Graphs &amp; Charts</h1>
      <p className='mb-5'>Graphs and charts are an integral part of how we display data in the platform. We turn to <a href="https://canvasjs.com/" target="_blank" rel="noreferrer">CanvasJS</a> to set up and render out charts.
        Please take the time to orient yourself on how to set up and use graphs and charts with CanvasJS.</p>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col'>
            <h3>Strategy Comparison</h3>
          </div>
        </div>
        <div className='row'>
          <div className='col mb-6'>
            <CanvasJSChart options={chart01}
             /* onRef = {ref => this.chart = ref} */
            />
          </div>
        </div>
      </div>
      <h2>Usage Guidelines</h2>
      <p>These guidelines are not set in stone, it is preferred you follow them.</p>
      <ul>
        <li>You can use any graph or chart you see fit for your needs, but it&rsquo;s ideal to have the stakeholder first give you an idea of what they would like.</li>
        <li>Please refrain from using the chart titles made by CanvasJS. Instead use HTML headers.</li>
        <li>Always try to keep the chart visual on brand. Use our brand font, <a href="https://fonts.google.com/specimen/Montserrat" target="_blank" rel="noreferrer">Montserrat</a>, in your parameters. Suggested font size is 14.</li>
        <li>When coloring your data elements, please stick to brand colors. Start with Power Kiosk Blue or Light Blue, then Orange, and go from there. Use Dark Gray for axis titles and labels.</li>
        <li>Try to not use too many similar shades of one color, as it will make the data hard to read. Create enough contrast in your color choices so the data is clear.</li>
        <li>Refrain from using area fills on busy charts, as it will hinder the clarity of other lines and items. A suggestion is to set the opacity of the area fill to 50% or lower so the other color choices contrast enough to stand out.</li>
        <li>Unless requested, try not to use markers in the normal state on lines. Set your marker size to 0 so it only shows up on hover.</li>
        <li>Please use a legend on all charts unless specifically asked not to. Make sure the names and labels you use make sense to the end user.</li>
        <li>Take advantage of the zoom feature on charts that contain a lot of data. If there is a possibility users will be confused, please add some instructions somewhere on the page.</li>
        <li>Be cognizant of how your graphs and charts lay out on all screens. Look at how they lay out in mobile and tablet sizes. Things won&rsquo;t always be perfect, but at least try.</li>
      </ul>
      <p>When you create your div to contain your chart, a suggested guideline would be inline styles of 400px-500px height and width set to auto.</p>
      <div className='copy-block-div mb-6'>
        <p className='semibold-text mb-0'>Code</p>
        <CopyBlock
          language="html"
          text={`<div id="yourCanvasJsGraph" style="height: 500px; width: 100%;"></div>`}
          theme={monoBlue}
          showLineNumbers={false}
          codeBlock
        />
      </div>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col'>
            <h3>Term Value Booked</h3>
          </div>
        </div>
        <div className='row'>
          <div className='col mb-6'>
            <CanvasJSChart options={chart02}
             /* onRef = {ref => this.chart = ref} */
            />
          </div>
        </div>
      </div>

      <h2>CanvasJS Tooltip</h2>
      <p>It&rsquo;s encouraged to use the tooltip, as trying to label things on the chart can cause unnecessary clutter, and no tooltip at all deprives users of seeing the detailed data.</p>
      <p>Here&rsquo;s a few guidelines to using the tooltip in charts on the platform:</p>
      <ul>
        <li>Unless specifically requested not to, always set the shared parameter to true, so one hover will show the end user all the data of that one X-axis point.</li>
        <li>The general layout should always place the date on top in dark gray, and then the other items in the chart, each colored by the color used in the chart.</li>
        <li>Make sure any colors you select in the chart also come in clearly in the tooltip. This can be challenging if the chart is on a dark background.</li>
        <li>Please format numbers properly to the data. Use commas for large numbers, and always try to use units and symbols that are needed for currency or unit of measure. It&rsquo;s ideal to use the valueFormatString parameters in CanvasJS, but if you are in need of a more custom solution, please try the JavaScript below to format the content.</li>
      </ul>
      <div className='copy-block-div mb-6'>
        <p className='semibold-text mb-0'>Code</p>
        <CopyBlock
          language="javascript"
          text={`// This goes in the setup on CanvasJS.
toolTip: {
  shared: true,
  content: toolTipContent,
},

// This goes below your setup as its own function.
function toolTipContent(e) {
  var entriesLength, finalString;
  var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  // Initial Date
  finalString = "<span style = \"color:#707070;\">" + monthNames[(e.entries[0].dataPoint.x).getMonth()] + " " + (e.entries[0].dataPoint.x).getFullYear() + "</span>";

  // Items
  for (let i = 0; i < e.entries.length; i++) {
    finalString += "<br/><span style= \"color:" + e.entries[i].dataSeries.color + "\"> " + e.entries[i].dataSeries.name + "</span>: " + e.entries[i].dataPoint.y + "";
  }
  return finalString;
};`}
          theme={monoBlue}
          showLineNumbers={false}
          codeBlock
        />
      </div>
    </>
  );
}

export default GraphsCharts;