import './_icons.scss';
import React from 'react';
import { CopyBlock, monoBlue } from "react-code-blocks";

function Icons() {
  return (
    <>
      <h1 className='mb-5'>Icons</h1>
      <h2>Font Awesome Icons</h2>
      <p>Most of the icons you see on the dashboard come from the <a href="https://fontawesome.com/" target="_blank" rel="noreferrer">Font Awesome Icon Library</a>. Feel free to use any icons needed, but try to keep a continuity of which icons you use for certain functions we already have on the dashboard.</p>
      <ul className='list-inline text-center mb-5'>
        <li className='list-inline-item reg-blue-text font-size-2 px-4'>
          <i className='fa-regular fa-square-plus'></i>
        </li>
        <li className='list-inline-item reg-blue-text font-size-2 px-4'>
          <i className='fa-solid fa-pencil'></i>
        </li>
        <li className='list-inline-item reg-blue-text font-size-2 px-4'>
          <i className='fa-solid fa-chart-pie'></i>
        </li>
        <li className='list-inline-item reg-blue-text font-size-2 px-4'>
          <i className='fa-solid fa-comments'></i>
        </li>
        <li className='list-inline-item reg-blue-text font-size-2 px-4'>
          <i className='fa-solid fa-list-check'></i>
        </li>
        <li className='list-inline-item reg-blue-text font-size-2 px-4'>
          <i className='fa-solid fa-pen-to-square'></i>
        </li>
        <li className='list-inline-item reg-blue-text font-size-2 px-4'>
          <i className='fa-solid fa-gear'></i>
        </li>
        <li className='list-inline-item reg-blue-text font-size-2 px-4'>
          <i className='fa-solid fa-share-from-square'></i>
        </li>
      </ul>
      <h3>Using Icons with Hyperlinks</h3>
      <p>You can use any Font Awesome icon with or without text as a hyperlink.</p>
      <ul className='list-unstyled ms-4 mb-4'>
        <li><a className='semibold-text reverse-deco' href="javascript:void(0)"><i className='fa-solid fa-link'></i>Hyperlink</a></li>
      </ul>
      <p>The icon will be sized and vertically aligned automatically, but you can modify these hyperlinks in a few ways.</p>
      <p><span className='magenta-text'>.gray-icon</span> will color the icon gray, but leave the text as is.</p>
      <ul className='list-unstyled ms-4 mb-4'>
        <li><a className='semibold-text reverse-deco gray-icon' href="javascript:void(0)"><i className='fa-solid fa-link'></i>Hyperlink</a></li>
      </ul>
      <p><span className='magenta-text'>.blue-gray-icon</span> will color the icon blue-gray, but leave the text as is.</p>
      <ul className='list-unstyled ms-4 mb-4'>
        <li><a className='semibold-text reverse-deco blue-gray-icon' href="javascript:void(0)"><i className='fa-solid fa-link'></i>Hyperlink</a></li>
      </ul>
      <p><span className='magenta-text'>.bigger-icon</span> will make the icon slightly bigger, in case you want it to stand out more from the text.</p>
      <ul className='list-unstyled ms-4 mb-4'>
        <li><a className='semibold-text reverse-deco bigger-icon' href="javascript:void(0)"><i className='fa-solid fa-link'></i>Hyperlink</a></li>
      </ul>
      <p><span className='magenta-text'>.no-right-margin</span> will remove just the right-margin attached to the icon. Ideal when you want an icon link without any text.</p>
      <ul className='list-inline ms-4 mb-4'>
        <li className='list-inline-item pe-4'><a className='semibold-text reverse-deco no-right-margin' href="javascript:void(0)"><i className='fa-solid fa-link'></i></a></li>
        <li className='list-inline-item'><a className='semibold-text reverse-deco no-right-margin' href="javascript:void(0)"><i className='fa-solid fa-link'></i>Hyperlink</a></li>
      </ul>
      <p><span className='magenta-text'>.reset-link-icon</span> will remove all formatting on the icon, including font size and vertical alignment.</p>
      <ul className='list-unstyled ms-4 mb-6'>
        <li><a className='semibold-text reverse-deco reset-link-icon' href="javascript:void(0)"><i className='fa-solid fa-link'></i>Hyperlink</a></li>
      </ul>
      <h3>Exporting FontAwesome icons to the Development Team</h3>
      <p>Please note the development team uses the Angular version of the icons, so when you are looking to send a prototype for integration, you will need to reformat your icon codes. Please use the following syntax:</p>
      <div className='copy-block-div mb-5'>
        <p className='semibold-text mb-0'>Code</p>
        <CopyBlock
          language="html"
          text={`<fa-icon [icon]="['fas', 'trash']"></fa-icon>`}
          theme={monoBlue}
          showLineNumbers={false}
          codeBlock
        />
      </div>
      <p className='mb-6'>Also make sure to double-check any icons you're bringing in from previous prototype builds. FontAwesome occasionally changes their syntax, and we need things current for the development team, even if it's working in your prototype.</p>
      <h2>Illustrated Icons</h2>
      <p>Beyond Font Awesome, we have a handful of icons we&rsquo;ve illustrated in-house solely for specialized purposes.  Please see the design team if any are needed.</p>
      <div className='container-fluid'>
        <div className='row mb-6'>
          <div className='col-12 col-md-6 col-xl text-center p-4'>
            <img src="/assets/img/electricity-icon.png" className='img-fluid' alt="Electricity Icon" />
          </div>
          <div className='col-12 col-md-6 col-xl text-center p-4'>
            <img src="/assets/img/electricity-icon-orange.png" className='img-fluid' alt="Single-Color Electricity Icon" />
          </div>
          <div className='col-12 col-md-6 col-xl text-center p-4'>
            <img src="/assets/img/gas-icon.png" className='img-fluid' alt="Natural Gas Icon" />
          </div>
          <div className='col-12 col-md-6 col-xl text-center p-4'>
            <img src="/assets/img/gas-icon-blue.png" className='img-fluid' alt="Single-Color Natural Gas Icon" />
          </div>
          <div className='col-12 col-xl text-center p-4'>
            <img src="/assets/img/sun-icon.png" className='img-fluid' alt="Community Solar Sun Icon" />
          </div>
        </div>
      </div>
    </>
  );
}

export default Icons;