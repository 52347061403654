import './_to-customers.scss';
import React from 'react';
// import { CopyBlock, monoBlue } from "react-code-blocks";

function ToCustomers() {
  return (
    <>
      <div className='row'>
        <div className='col'>
          <h1>Emails to Customers</h1>
        </div>
      </div>
      <div className='row'>
        <div className='col-12 col-xl-7 order-1 order-xl-0'>
          <p>Emails to customers should be handled slightly different from those alert emails we send to brokers. This is a direct communication with the actual customers buying energy plans from our broker partners, so a certain level of marketing and customer relations is needed.</p>
          <p>Please use a full hero image much like we do for newsletters. We want to call similarity to the content we&rsquo;re about to send, but also be a more engaging and professional visual opening to our message.</p>
          <p>Speak in a friendly, professional tone to the customer. Best practice is to write the email as if you are the broker speaking personally to your own customer.  Don&rsquo;t be afraid to put in variables that call out the customer&rsquo;s name, or even sign off with the name of the brokerage or even the agent.</p>
          <p>Try to still stay on point. Come in with why the customer is receiving this email, give them the instruction or information they need to see, and leave a call-to-action if needed.</p>
          <p>Please try to refrain from using an email as a web page unless it&rsquo;s absolutely necessary. If you&rsquo;re asked to make an email loaded with way more information than could be normally consumed in an email, push to condense things down to a simple message that leads into a call-to-action that takes the user to the dashboard for the heavy details.</p>
        </div>
        <div className='col-12 col-xl-5 order-0 order-xl-1 mb-4 mb-xl-0'>
          <img src="/assets/img/customer-email-image.jpg" alt="Email on phone" className='img-fluid shadow' />
        </div>
      </div>
    </>
  );
}

export default ToCustomers;