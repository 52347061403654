import './_toastrs.scss';
import React from 'react';
import { CopyBlock, monoBlue } from "react-code-blocks";

function Toastrs() {
  return (
    <>
      <h1>Toastrs</h1>
      <p>A toastr is a smaller alert that normally appears at the top of a page and then vanishes after a set period of time. For developers, we create these toastrs using <a href="https://github.com/scttcper/ngx-toastr" target="_blank" rel="noreferrer">ngx-toastr by scttcper</a>, but for mockup purposes, we have code samples you can use.</p>
      <div className="toast-container toast-top-center">
        <div
          className="ng-tns-c15-3 ng-star-inserted ng-trigger ng-trigger-flyInOut ngx-toastr toast-info"
          toast-component=""
          style={{ opacity: 1 }}
        >
          <div
            role="alertdialog"
            aria-live="polite"
            className="toast-message ng-star-inserted"
            aria-label="Info lorem ipsum dolor sit amet, consectetur adipiscing elit."
          >
            Info lorem ipsum dolor sit amet, consectetur adipiscing elit.
          </div>
        </div>
      </div>
      <div className='copy-block-div mb-5'>
        <p className='semibold-text mb-0'>Code</p>
        <CopyBlock
          language="html"
          text={`<div class="overlay-container">
    <div id="toast-container" class="toast-container toast-top-center">
        <div class="ng-tns-c15-3 ng-star-inserted ng-trigger ng-trigger-flyInOut ngx-toastr toast-info" toast-component="" style="opacity: 1;">
            <div role="alertdialog" aria-live="polite" class="ng-tns-c15-1 toast-message ng-star-inserted" aria-label="Info lorem ipsum dolor sit amet, consectetur adipiscing elit.">
                Info lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </div>
        </div>
    </div>
</div>`}
          theme={monoBlue}
          showLineNumbers={false}
          codeBlock
        />
      </div>
      <p>The <span className='magenta-text'>.overlay-container</span> div is necessary to set the mockup on top of whatever content you have on your page. The <span className='magenta-text'>.toast-container</span> div contains the basic styling and setup for the toastr, alongside are your choice of placement classes:</p>
      <table className='table mt-2 mb-6'>
        <thead>
          <tr>
            <th scope="col">Code</th>
            <th scope="col">Result</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td scope="row" className='magenta-text'>.toast-center-center</td>
            <td>Toastr placed in the center of the window.</td>
          </tr>
          <tr>
            <td scope="row" className='magenta-text'>.toast-top-center</td>
            <td>Toastr placed at the top, but centered in the window.</td>
          </tr>
          <tr>
            <td scope="row" className='magenta-text'>.toast-bottom-center</td>
            <td>Toastr placed at the bottom, but centered in the window.</td>
          </tr>
          <tr>
            <td scope="row" className='magenta-text'>.toast-top-full-width</td>
            <td>Toastr placed at the top, but stretched to the full width of the window.</td>
          </tr>
          <tr>
            <td scope="row" className='magenta-text'>.toast-bottom-full-width</td>
            <td>Toastr placed at the bottom, but stretched to the full width of the window.</td>
          </tr>
          <tr>
            <td scope="row" className='magenta-text'>.toast-top-left</td>
            <td>Toastr placed at the top and left side of the window.</td>
          </tr>
          <tr>
            <td scope="row" className='magenta-text'>.toast-top-right</td>
            <td>Toastr placed at the top and right side of the window.</td>
          </tr>
          <tr>
            <td scope="row" className='magenta-text'>.toast-bottom-left</td>
            <td>Toastr placed at the bottom and left side of the window.</td>
          </tr>
          <tr>
            <td scope="row" className='magenta-text'>.toast-bottom-right</td>
            <td>Toastr placed at the bottom and right side of the window.</td>
          </tr>
        </tbody>
      </table>
      <h2>Style classes for Toastrs</h2>
      <p>There are four style classes you can use to decorate your Toastr. You simply add your selected style to the div within the <span className='magenta-text'>.toast-container</span> div. Please do not touch any of the <span className='magenta-text'>.ng-</span> classes or the <span className='magenta-text'>.toast-info</span> class, as it will destroy the layout.</p>
      <div className='container-fluid mb-5'>
        <div className='row'>
          <div className='col col-md-6 mb-4'>
            <div className="toast-container toast-top-center">
              <div
                className="ng-tns-c15-3 ng-star-inserted ng-trigger ng-trigger-flyInOut ngx-toastr toast-info"
                toast-component=""
                style={{ opacity: 1 }}
              >
                <div
                  role="alertdialog"
                  aria-live="polite"
                  className="toast-message ng-star-inserted"
                  aria-label="Info lorem ipsum dolor sit amet, consectetur adipiscing elit."
                >
                  Info lorem ipsum dolor sit amet, consectetur adipiscing elit.
                </div>
              </div>
            </div>
            <p className='text-center magenta-text'>.toast-info</p>
          </div>
          <div className='col col-md-6 mb-4'>
            <div className="toast-container toast-top-center">
              <div
                className="ng-tns-c15-4 ng-star-inserted ng-trigger ng-trigger-flyInOut ngx-toastr toast-success"
                toast-component=""
                style={{ opacity: 1 }}
              >
                <div
                  role="alertdialog"
                  aria-live="polite"
                  className="ng-tns-c15-1 toast-message ng-star-inserted"
                  aria-label="Success lorem ipsum dolor sit amet, consectetur adipiscing elit."
                >
                  Success lorem ipsum dolor sit amet, consectetur adipiscing elit.
                </div>
              </div>
            </div>
            <p className='text-center magenta-text'>.toast-success</p>
          </div>
          <div className='col col-md-6 mb-4'>
            <div className="toast-container toast-top-center">
              <div
                className="ng-tns-c15-2 ng-star-inserted ng-trigger ng-trigger-flyInOut ngx-toastr toast-warning"
                toast-component=""
                style={{ opacity: 1 }}
              >
                <div
                  role="alertdialog"
                  aria-live="polite"
                  className="ng-tns-c15-1 toast-message ng-star-inserted"
                  aria-label="Warning lorem ipsum dolor sit amet, consectetur adipiscing elit."
                >
                  Warning lorem ipsum dolor sit amet, consectetur adipiscing elit.
                </div>
              </div>
            </div>
            <p className='text-center magenta-text'>.toast-warning</p>
          </div>
          <div className='col col-md-6 mb-4'>
            <div className="toast-container toast-top-center">
              <div
                className="ng-tns-c15-1 ng-star-inserted ng-trigger ng-trigger-flyInOut ngx-toastr toast-error"
                toast-component=""
                style={{ opacity: 1 }}
              >
                <div
                  role="alertdialog"
                  aria-live="polite"
                  className="ng-tns-c15-1 toast-message ng-star-inserted"
                  aria-label="Error lorem ipsum dolor sit amet, consectetur adipiscing elit."
                >
                  Error lorem ipsum dolor sit amet, consectetur adipiscing elit.
                </div>
              </div>
            </div>
            <p className='text-center magenta-text'>.toast-error</p>
          </div>
        </div>
      </div>
      <h2>Usage Guidelines</h2>
      <p>There are proper uses of each type of toastr in our dashboard. While these guidelines are not set in stone, it is preferred you follow them.</p>
      <ul>
        <li>Use toasters only when the information is small and will vanish after a short period of time. For information you want to stay in view, use an <a href="/components">alert</a></li>
        <li>Please use the <span className='magenta-text'>.toast-success</span> style for quick form submission confirmations. If you need a larger amount of information, consider a full confirmation page.</li>
        <li>It is suggested to use the <span className='magenta-text'>.toast-warning</span> style for small issue notifications, and the <span className='magenta-text'>.toast-error</span> style for form submission errors.</li>
        <li>For any and all general toastrs or ones the three styles do not fit, use the <span className='magenta-text'>.toast-info</span> style</li>
      </ul>
    </>
  );
}

export default Toastrs;