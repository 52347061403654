import './_components.scss';
import {
  Routes,
  Route
} from "react-router-dom";
import Buttons from './buttons/Buttons';
import ContentDiv from './content-div/ContentDiv';
import Dropdowns from './dropdowns/Dropdowns';
import FormElements from './form-elements/FormElements';
import GraphsCharts from './graphs-charts/GraphsCharts';
import Modals from './modals/Modals';
import Steps from './steps/Steps';
import Tables from './tables/Tables';
import Toastrs from './toastrs/Toastrs';
import { CopyBlock, monoBlue } from "react-code-blocks";

function Components() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Alerts />} />
        <Route path="buttons" element={<Buttons />} />
        <Route path="content-div" element={<ContentDiv />} />
        <Route path="dropdowns" element={<Dropdowns />} />
        <Route path="form-elements" element={<FormElements />} />
        <Route path="graphs-charts" element={<GraphsCharts />} />
        <Route path="modals" element={<Modals />} />
        <Route path="steps" element={<Steps />} />
        <Route path="tables" element={<Tables />} />
        <Route path="toastrs" element={<Toastrs />} />
      </Routes>
    </>
  );
}


function Alerts() {
  return (
    <>
      <h1>Alerts</h1>
      <p className='mb-6'>While alerts are mainly used on contract details pages, they can actually be used anywhere. You can also use <a href="/components/toastrs">Toastrs</a> if you do not wish for a larger alert.</p>
      <h2>The Basic Alert</h2>
      <p>The basic alert utilizes some of how Bootstrap handles alerts, but with our own styling:</p>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col'>
            <div className='alert alert-notification fade show' role="alert">
              <div className='row d-flex align-items-center justify-content-center alert-layout'>
                <div className='col-auto alert-icon'>
                  <i className='fas fa-exclamation-circle'></i>
                </div>
                <div className='col ps-0 alert-content'>
                  <p className='my-2'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus nec nisl purus. Fusce convallis cursus lectus, ac viverra lorem.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='copy-block-div mb-5'>
        <p className='semibold-text mb-0'>Code</p>
        <CopyBlock
          language="html"
          text={`<div class="row">
  <div class="col">
    <div class="alert alert-notification fade show" role="alert">
      <div class="row d-flex align-items-center justify-content-center alert-layout">
        <div class="col-auto alert-icon">
          <i class="fas fa-exclamation-circle"></i>
        </div>
        <div class="col pl-0 alert-content">
          <p class="my-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus nec nisl purus. Fusce convallis cursus lectus, ac viverra lorem.</p>
        </div>
      </div>
    </div>
  </div>
</div>`}
          theme={monoBlue}
          showLineNumbers={false}
          codeBlock
        />
      </div>
      <p>The icon is not automatically placed based on how you lay out your alert. You need to pick and choose which you desire. For general alerts, we prefer the circle with an exclamation point.</p>
      <p>If you do not want your alert to fill the width of the window, you can add the <span className='magenta-text'>.alert-width-limit</span> class to the div containing the <span className='magenta-text'>.alert-notification</span> class. This will limit the width of the alert to 768px and center it on screen:</p>
      <div className='container-fluid mb-6'>
        <div className='row'>
          <div className='col'>
            <div className='alert alert-notification alert-width-limit fade show' role="alert">
              <div className='row d-flex align-items-center justify-content-center alert-layout'>
                <div className='col-auto alert-icon'>
                  <i className='fas fa-exclamation-circle'></i>
                </div>
                <div className='col ps-0 alert-content'>
                  <p className='my-2'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus nec nisl purus. Fusce convallis cursus lectus, ac viverra lorem.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <h2>Green Positive Alert</h2>
      <p>The Green Alert is ideal for success messages and positive messaging. Simply add the <span className='magenta-text'>.green-alert</span> class to the div containing the <span className='magenta-text'>.alert-notification</span> class. For the icon, we recommend the circle with a checkmark.</p>
      <div className='container-fluid mb-6'>
        <div className='row'>
          <div className='col'>
            <div className='alert alert-notification green-alert fade show' role="alert">
              <div className='row d-flex align-items-center justify-content-center alert-layout'>
                <div className='col-auto alert-icon'>
                  <i className='fas fa-check-circle'></i>
                </div>
                <div className='col ps-0 alert-content'>
                  <p className='my-2'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus nec nisl purus. Fusce convallis cursus lectus, ac viverra lorem.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <h2>Orange Warning Alert</h2>
      <p>The Orange Alert is ideal for warning messages that are not necessarily critical. Simply add the <span className='magenta-text'>.orange-alert</span> class to the div containing the <span className='magenta-text'>.alert-notification</span> class. For the icon, we recommend the triangle with an exclamation mark.</p>
      <div className='container-fluid mb-6'>
        <div className='row'>
          <div className='col'>
            <div className='alert alert-notification orange-alert fade show' role="alert">
              <div className='row d-flex align-items-center justify-content-center alert-layout'>
                <div className='col-auto alert-icon'>
                  <i className='fas fa-exclamation-triangle'></i>
                </div>
                <div className='col ps-0 alert-content'>
                  <p className='my-2'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus nec nisl purus. Fusce convallis cursus lectus, ac viverra lorem.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <h2>Red Warning Alert</h2>
      <p>The Orange Alert is ideal for warning messages that are not necessarily critical. Simply add the <span className='magenta-text'>.red-alert</span> class to the div containing the <span className='magenta-text'>.alert-notification</span> class. For the icon, we recommend the triangle with an exclamation mark.</p>
      <div className='container-fluid mb-6'>
        <div className='row'>
          <div className='col'>
            <div className='alert alert-notification red-alert fade show' role="alert">
              <div className='row d-flex align-items-center justify-content-center alert-layout'>
                <div className='col-auto alert-icon'>
                  <i className='fas fa-exclamation-triangle'></i>
                </div>
                <div className='col ps-0 alert-content'>
                  <p className='my-2'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus nec nisl purus. Fusce convallis cursus lectus, ac viverra lorem.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Components;