import './_buttons.scss';
import React from 'react';
import { CopyBlock, monoBlue } from "react-code-blocks";

function Buttons() {
  return (
    <>
      <h1>Buttons</h1>
      <p>Bootstrap offers nine styles of buttons you can use, we mainly focus on five styles for our needs. The style classes can be used either as HTML buttons or as hyperlinks:</p>
      <div className='container-fluid mb-4'>
        <div className='row'>
          <div className='col text-center'>
            <button type="button" className='btn btn-primary mx-1'>Primary</button>
            <button type="button" className='btn btn-secondary mx-1'>Secondary</button>
            <button type="button" className='btn btn-info mx-1'>Info</button>
            <button type="button" className='btn btn-light mx-1'>Light</button>
            <button type="button" className='btn btn-link mx-1'>Link</button>
          </div>
        </div>
      </div>
      <div className='copy-block-div mb-6'>
        <p className='semibold-text mb-0'>Code</p>
        <CopyBlock
          language="html"
          text={`<button type="button" class="btn btn-primary">Primary</button>
<button type="button" class="btn btn-secondary">Secondary</button>
<button type="button" class="btn btn-info">Info</button>
<button type="button" class="btn btn-light">Light</button>
<button type="button" class="btn btn-link">Link</button>

<a href="#" role="button" class="btn btn-primary">Primary</a>
<a href="#" role="button" class="btn btn-secondary">Secondary</a>
<a href="#" role="button" class="btn btn-info">Info</a>
<a href="#" role="button" class="btn btn-light">Light</a>
<a href="#" role="button" class="btn btn-link">Link</a>`}
          theme={monoBlue}
          showLineNumbers={false}
          codeBlock
        />
      </div>
      <h2>Usage Guidelines</h2>
      <p>There are proper uses of each type of button in our dashboard. While these guidelines are not set in stone, it is preferred you follow them.</p>
      <ul>
        <li>Try to only use the Primary style for the final submit button of a page, or if you're doing multiple-page forms, use it as the continue.</li>
        <li>The primary style can also be used for submission of <a href="/components/opening-form">Opening Forms</a> that you'll see on some sections.</li>
        <li>Use the Secondary button for those sub-forms within bigger forms. Examples are single-use clicks on a page, sign-up buttons on <a href="/components/rate-card">rate cards</a>, and submission buttons for editing sections of adding a customer. The darker Primary button serves as the final submit.</li>
        <li>The Info button style is mainly used on input groups, but can be used anywhere there is a need.</li>
        <li>The Light button style is mainly used in button groups as the "unchecked" state. Please do not use them as cancel buttons for forms. Use the Link style instead, unless you are showing a form with Yes and No buttons.</li>
        <li>The Link button is mainly used for cancel or "go back" buttons on forms.</li>
        <li>Please refrain from using buttons as basic hyperlinks. Yes, you can use hyperlinks as buttons, but stylistically we prefer a text hyperlink or a text hyperlink with an icon.</li>
      </ul>
    </>
  );
}

export default Buttons;