import './_emails.scss';
import {
  Routes,
  Route
} from "react-router-dom";
import AlertEmails from './alert-emails/AlertEmails';
import Boilerplate from './boilerplate/Boilerplate';
import Newsletters from './newsletters/Newsletters';
import ToCustomers from './to-customers/ToCustomers';
import WhiteLabeling from './white-labeling/WhiteLabeling';

function Emails() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Mjml />} />
        <Route path="alert-emails" element={<AlertEmails />} />
        <Route path="boilerplate" element={<Boilerplate />} />
        <Route path="newsletters" element={<Newsletters />} />
        <Route path="customer-emails" element={<ToCustomers />} />
        <Route path="white-labeling" element={<WhiteLabeling />} />
      </Routes>
    </>
  );
}


function Mjml() {
  return (
    <>
      <h1>MJML</h1>
      <p>Power Kiosk emails are created using a framework called <a href="https://mjml.io/" target="_blank" rel="noreferrer" className='semibold-text'>MJML</a></p>
      <p className='text-center'><a href="https://mjml.io/" target="_blank" rel="noreferrer" className='semibold-text'><img src="/assets/img/mjml-logo.png" alt="MJML Logo" className='img-fluid' /></a></p>
      <p><a href="https://mjml.io/" target="_blank" rel="noreferrer" className='semibold-text'>MJML</a> is a solid email framework that takes much of the guesswork out of creating emails that are compliant with most email clients. It works by having you create a layout using their markup language in some conjunction with HTML and CSS, and you run a complier to create compliant code you can use in any e-blast.</p>
      <p>Please take the time to familiarize yourself with MJML and how it works. They offer <a href="https://marketplace.visualstudio.com/items?itemName=mjmlio.vscode-mjml" target="_blank" rel="noreferrer" className='semibold-text'>extensions for Visual Studio Code</a> which can give you a means to preview your work as you build. There are also compilers available for those who do not wish to use Visual Studio Code.</p>
      <p>Our <a href="/emails/boilerplate" className='semibold-text'>Boilerplate</a> contains practically all the components you would need to make almost any kind of email for Power Kiosk.</p>
      <p>It is suggested when you are assigned a task to create an email, you simply prototype it in MJML versus making a flat layout for approval. This will save time and give us a finished item when approved.</p>
      <p>The MJML compilers also will turn out minified code. Please either fix your settings (if you can) or run an extension to un-minify the code before handing it off to Operations for Constant Contact use, and especially for the development team for integration into the dashboard.</p>
    </>
  );
}

export default Emails