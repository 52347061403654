import './_typography.scss';
import React from 'react';
import { CopyBlock, monoBlue } from "react-code-blocks";

function Typography() {
  return (
    <>
      <h1 className='mb-5'>Typography</h1>
      <h2>Fonts</h2>
      <p>Our main brand font is Montserrat, but we only have a few weights and variants installed:</p>
      <ul className='list-unstyled ms-4'>
        <li className='font-size-15 medium-text'>Montserrat Medium</li>
        <li className='font-size-15 medium-text'><em>Montserrat Medium Italic</em></li>
        <li className='font-size-15 semibold-text'>Montserrat Semibold</li>
        <li className='font-size-15 semibold-text'><em>Montserrat Semibold Italic</em></li>
        <li className='font-size-15 bold-text'>Montserrat Bold</li>
        <li className='font-size-15 bold-text'><em>Montserrat Bold Italic</em></li>
      </ul>
      <p className='mb-4'>Montserrat should be used on all content unless otherwise noted, or the component or item requires a different font.  Montserrat Medium is what should be used on all normal block copy, while Semibold and Bold are used sparingly.</p>
      <p>We also use Roboto in some areas, mainly on the top navigation bars.</p>
      <ul className='list-unstyled ms-4 mb-4'>
        <li className='roboto-font font-size-2'>Roboto</li>
      </ul>
      <p>Finally the Kanit font is also imported for only one purpose. We use it for our PowerScore branding:</p>
      <ul className='list-unstyled ms-4 mb-6'>
        <li className='kanit-font font-size-2'>Kanit Bold Italic</li>
        <li><span className='powerscore-text font-size-2'>PowerScore</span></li>
      </ul>
      <h2>Headers</h2>
      <p>Headers in the dashboard are the same ideology as in modern HTML:</p>
      <table className='table mt-2 mb-4'>
        <thead>
          <tr>
            <th scope="col">Code</th>
            <th scope="col">Example</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td scope="row" className='magenta-text'>&lt;h1&gt;&lt;/h1&gt;</td>
            <td><h1>h1 Heading</h1></td>
          </tr>
          <tr>
            <td scope="row" className='magenta-text'>&lt;h2&gt;&lt;/h2&gt;</td>
            <td><h2>h2 Heading</h2></td>
          </tr>
          <tr>
            <td scope="row" className='magenta-text'>&lt;h3&gt;&lt;/h3&gt;</td>
            <td><h3>h3 Heading</h3></td>
          </tr>
          <tr>
            <td scope="row" className='magenta-text'>&lt;h4&gt;&lt;/h4&gt;</td>
            <td><h4>h4 Heading</h4></td>
          </tr>
          <tr>
            <td scope="row" className='magenta-text'>&lt;h5&gt;&lt;/h5&gt;</td>
            <td><h5>h5 Heading</h5></td>
          </tr>
        </tbody>
      </table>
      <p>You can also add a container-wide underline beneath the header by adding the <span className='magenta-text'>.add-underline</span> class to your header.</p>
      <h1 className='add-underline ms-4 mb-3'>Heading</h1>
      <p className='mb-6'>There are some sections where things are reversed, and headers will come with an underline. If so, you can remove the underline with the <span className='magenta-text'>.no-underline</span> class.</p>
      <h2>Font Sizes</h2>
      <p>The base font size for the dashboard is 14px, but we use rem measurements to change font sizes. Unless the component has its own font size set, you can change font sizes of almost anything with these CSS classes:</p>
      <table className='table mt-2 mb-6'>
        <thead>
          <tr>
            <th scope="col">CSS Class</th>
            <th scope="col">Measurment/Example</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td scope="row" className='magenta-text'>.font-size-075</td>
            <td className='font-size-075'>0.75rem Text</td>
          </tr>
          <tr>
            <td scope="row" className='magenta-text'>.font-size-085</td>
            <td className='font-size-085'>0.85rem Text</td>
          </tr>
          <tr>
            <td scope="row" className='magenta-text'>.font-size-095</td>
            <td className='font-size-095'>0.95rem Text</td>
          </tr>
          <tr>
            <td scope="row" className='magenta-text'>.font-size-1</td>
            <td className='font-size-1'>1rem Text</td>
          </tr>
          <tr>
            <td scope="row" className='magenta-text'>.font-size-125</td>
            <td className='font-size-1.25'>1.25rem Text</td>
          </tr>
          <tr>
            <td scope="row" className='magenta-text'>.font-size-15</td>
            <td className='font-size-15'>1.5rem Text</td>
          </tr>
          <tr>
            <td scope="row" className='magenta-text'>.font-size-175</td>
            <td className='font-size-175'>1.75rem Text</td>
          </tr>
          <tr>
            <td scope="row" className='magenta-text'>.font-size-2</td>
            <td className='font-size-2'>2rem Text</td>
          </tr>
        </tbody>
      </table>
      <h2>Font Weights</h2>
      <p>There are a few CSS classes in place to change the weight of a font beyond the traditional <span className='magenta-text'>&lt;strong&gt;&lt;/strong&gt;</span> tag.</p>
      <table className='table mt-2 mb-6'>
        <thead>
          <tr>
            <th scope="col">CSS Class</th>
            <th scope="col">Example</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td scope="row"><span className='magenta-text'>.text-strong</span> or <span className='magenta-text'>.bold-text</span></td>
            <td className='bold-text'>Text</td>
          </tr>
          <tr>
            <td scope="row" className='magenta-text'>.semibold-text</td>
            <td className='semibold-text'>Text</td>
          </tr>
          <tr>
            <td scope="row" className='magenta-text'>.medium-text</td>
            <td className='medium-text'>Text</td>
          </tr>
        </tbody>
      </table>
      <h2>Hyperlinks</h2>
      <p>The default hyperlink is light blue with a moderate blue hover:</p>
      <ul className='list-unstyled ms-4 mb-4'>
        <li><a href="javascript:void(0)">Hyperlink</a></li>
      </ul>
      <p>Most times, we will also add in the <span className='magenta-text'>.semibold-text</span> class to make hyperlinks stand out from normal copy:</p>
      <ul className='list-unstyled ms-4 mb-4'>
        <li><a className='semibold-text' href="javascript:void(0)">Hyperlink</a></li>
      </ul>
      <p>You can also recolor hyperlinks using the following classes:</p>
      <table className='table mt-2 mb-4'>
        <thead>
          <tr>
            <th scope="col">CSS Class</th>
            <th scope="col">Example</th>
          </tr>
        </thead>
        <tbody>
        <tr>
            <td scope="row" className='magenta-text'>.gray-link</td>
            <td><a className='semibold-text reverse-deco gray-link' href="javascript:void(0)">Hyperlink</a></td>
          </tr>
          <tr>
            <td scope="row" className='magenta-text'>.red-link</td>
            <td><a className='semibold-text reverse-deco red-link' href="javascript:void(0)">Hyperlink</a></td>
          </tr>
          <tr>
            <td scope="row" className='magenta-text'>.green-link</td>
            <td><a className='semibold-text reverse-deco green-link' href="javascript:void(0)">Hyperlink</a></td>
          </tr>
          <tr>
            <td scope="row" className='magenta-text'>.dark-blue-link</td>
            <td><a className='semibold-text reverse-deco dark-blue-link' href="javascript:void(0)">Hyperlink</a></td>
          </tr>
        </tbody>
      </table>
      <p>A hyperlink can also be put in a disabled state by adding the <span className='magenta-text'>.disabled</span> class to the <span className='magenta-text'>&lt;a&gt;</span> tag.</p>
      <ul className='list-unstyled ms-4 mb-6'>
        <li><a className='disabled' href="javascript:void(0)">Hyperlink</a></li>
      </ul>
      <h2>Big Number</h2>
      <p>This is a style setup mainly to use in the SaaS system, but it's also handy for when you need to show big &ldquo;overall&rdquo; numbers on pages for quick scanning.</p>
      <p className='big-number-label ms-4'>Big Number Label</p>
      <p className='big-number ms-4 mb-4'><sup>$</sup>1,234,567.89</p>
      <div className='copy-block-div mb-4'>
        <p className='semibold-text mb-0'>Code</p>
        <CopyBlock
          language="html"
          text={`<p class="big-number-label">Big Number Label</p>
<p class="big-number"><sup>$</sup>1,234,567.89</p>`}
          theme={monoBlue}
          showLineNumbers={false}
          codeBlock
        />
      </div>
      <p className='mb-5'>The classes are best used as two paragraphs. The label will remove the bottom margin to keep the layout tight. There is also styling for encapsulating the dollar sign in <span className='magenta-text'>&lt;sup&gt;</span>, as it will resize the symbol proportionately to the large number.</p>
    </>
  );
}

export default Typography;