import './_modals.scss';
import React from 'react';
//import { CopyBlock, monoBlue } from "react-code-blocks";

function Modals() {
  return (
    <>
      <h1>Modals</h1>
      <p className='mb-4'>Modals are a very handy tool for small forms and displaying a larger amount of information that should not be crammed into an <a href="/components">alert</a> or <a href="/components/toastrs">toastr</a>.</p>
      <div
        className="modal pk-modal fade show h-auto"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-modal="true"
        role="dialog"
        style={{ display: "block" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Modal title</h5>
              <button
                type="button"
                className="close"
                aria-label="Close"
              >
                <span aria-hidden="true">
                  <i className="fas fa-times" />
                </span>
              </button>
            </div>
            <div className="modal-body">
              <p>Modal body text goes here.</p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-link btn-sm"
              >
                Cancel
              </button>
              <button type="button" className="btn btn-primary btn-sm">
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
      <h2>Setting up a Modal</h2>
      <p>Setting up a modal isn't difficult. You simply use the <a href="https://getbootstrap.com/docs/4.5/components/modal/" target="_blank" rel="noreferrer">code Bootstrap provides</a> and add in the 
        CSS class <span className='magenta-text'>.pk-modal</span> to the outermost div that contains the class <span className='magenta-text'>.modal</span>.</p>
      <p className='mb-6'>With the addition of more width breakpoints, you also have access to larger-size modals should you need them. You can 
        use <span className='magenta-text'>.modal-xxl</span> and <span className='magenta-text'>.modal-xxxl</span> along with the other modal size classes Bootstrap provides.</p>
      <h2>Usage Guidelines</h2>
      <p>These guidelines are not set in stone, it is preferred you follow them.</p>
      <ul>
        <li>Do not use a modal for what could be used in an alert or toastr. A modal should be brought in as an alert when more information is needed to be shown that can fit in one of the other alternatives.</li>
        <li>Please do not use modals as a replacement for a full web page. If you need a pop-up form filled with more than 6-8 fields, consider rethinking your flow to be opening a new page.</li>
        <li>Float submit and cancel buttons to the right, unless this is a yes/no or confirm/cancel modal, which you can center them.</li>
        <li>Please use the <span className='magenta-text'>.btn-link</span> class on cancel buttons.</li>
        <li>Include a cancel button in all your modals.</li>
        <li>Use the set <span className='magenta-text'>.pk-modal</span> class for styling modals unless it is for a special need. The Feedback modal is an example.</li>
      </ul>
    </>
  );
}

export default Modals;