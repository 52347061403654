import './_bootstrap-overview.scss';
import React from 'react';
import { CopyBlock, monoBlue } from "react-code-blocks";

function BootstrapOverview() {
  return (
    <>
      <h1>Bootstrap</h1>
      <p>Much of the layout and responsive functionality of the dashboard is brought in part through Twitter&rsquo;s Bootstrap toolkit.  Currently we use <a href="https://getbootstrap.com/docs/4.5/getting-started/introduction/" target="_blank" rel="noreferrer">version 4.5</a>, but have made modifications to the system to accommodate larger screen resolutions.</p>
      <p>Our customized build of Bootstrap contains extra breakpoints:</p>
      <table className='table mt-2 mb-5'>
        <thead>
          <tr>
            <th scope="col">Breakpoint</th>
            <th scope="col" className='text-center'>Min Width (px)</th>
            <th scope="col" className='text-center'>Max Width (px)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td scope="row">Extra-Small (xs)</td>
            <td className='text-center'>1</td>
            <td className='text-center'>575</td>
          </tr>
          <tr>
            <td scope="row">Small (sm)</td>
            <td className='text-center'>576</td>
            <td className='text-center'>767</td>
          </tr>
          <tr>
            <td scope="row">Medium (md)</td>
            <td className='text-center'>768</td>
            <td className='text-center'>991</td>
          </tr>
          <tr>
            <td scope="row">Large (lg)</td>
            <td className='text-center'>991</td>
            <td className='text-center'>1999</td>
          </tr>
          <tr>
            <td scope="row">Extra-Large (xl)</td>
            <td className='text-center'>1200</td>
            <td className='text-center'>1439</td>
          </tr>
          <tr className='faint-orange-bg'>
            <td scope="row">Extra-Extra-Large (xxl)</td>
            <td className='text-center'>1440</td>
            <td className='text-center'>1919</td>
          </tr>
          <tr className='faint-orange-bg'>
            <td scope="row">Extra-Extra-Extra-Large (xxxl)</td>
            <td className='text-center'>1920</td>
            <td className='text-center'>None</td>
          </tr>
        </tbody>
      </table>
      <p>These breakpoints not only apply to Bootstrap&rsquo;s grid system, but also to components such as modals, and other areas that call upon the breakpoints.</p>
      <p className='mb-6'>You should try to utilize any and all classes and components in Bootstrap before creating new ones.  Examples would be spacing classes for margins and padding, form components, modals, cards, and collapses.</p>
      <h2>Additional Layout Classes</h2>
      <p>Beyond just Bootstrap's layout tools, we've also created a handful of CSS classes to help set the width of content on a page.</p>
      <p>The <span className='magenta-text'>.width-limit</span> class allows you center a piece of content on your screen, but it never be wider than 1080px. This is ideal to center and condense a piece of content on a large screen, but it will go to the full window width on smaller screns and mobile devices. The ideal place to put this class are on <span className='magenta-text'>.row</span> divs.</p>
      <div className='container-fluid'>
        <div className='row width-limit'>
          <div className='col p-3 mb-3 example-div shaded'>
            <p className='mb-6 magenta-text'>.width-limit</p>
          </div>
        </div>
      </div>
      <div className='copy-block-div mb-5'>
        <p className='semibold-text mb-0'>Code</p>
        <CopyBlock
          language="html"
          text={`<div class="row width-limit">
    <div class="col">
        <p>.width-limit</p>
    </div>
</div>`}
          theme={monoBlue}
          showLineNumbers={false}
          codeBlock
        />
      </div>
      <p>We also have three classes if you just wish to have a component have a maximum width. These are ideal for situations where you do not need centering, but need items to stop expanding across the screen at a certain width.</p>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col p-3 mb-3 maxwidth-800 example-div shaded'>
            <p className='mb-5'>Maximum Width = 800px<br /><span className='magenta-text'>.maxwidth-800</span></p>
          </div>
        </div>
        <div className='row'>
          <div className='col p-3 mb-3 maxwidth-1080 example-div shaded'>
            <p className='mb-5'>Maximum Width = 1080px<br /><span className='magenta-text'>.maxwidth-1080</span></p>
          </div>
        </div>
        <div className='row mb-6'>
          <div className='col p-3 mb-3 maxwidth-1200 example-div shaded'>
            <p className='mb-5'>Maximum Width = 1200px<br /><span className='magenta-text'>.maxwidth-1200</span></p>
          </div>
        </div>
        <p>Lastly, if you just need to quickly set a simple width on a component, try these classes based on Bootstrap's <span className='magenta-text'>.w-100</span> class:</p>
        <div className='row'>
          <div className='col mb-3 px-0'>
            <p className='p-3 w-auto example-div shaded'>Width = auto<br /><span className='magenta-text'>.w-auto</span></p>
          </div>
        </div>
        <div className='row'>
          <div className='col mb-3 px-0'>
            <p className='p-3 w-10 example-div shaded'>Width = 10%<br /><span className='magenta-text'>.w-10</span></p>
          </div>
        </div>
        <div className='row'>
          <div className='col mb-3 px-0'>
            <p className='p-3 w-15 example-div shaded'>Width = 15%<br /><span className='magenta-text'>.w-15</span></p>
          </div>
        </div>
        <div className='row'>
          <div className='col mb-3 px-0'>
            <p className='p-3 w-33 example-div shaded'>Width = 33%<br /><span className='magenta-text'>.w-33</span></p>
          </div>
        </div>
        <div className='row'>
          <div className='col mb-3 px-0'>
            <p className='p-3 w-45 example-div shaded'>Width = 45%<br /><span className='magenta-text'>.w-45</span></p>
          </div>
        </div>
        <div className='row'>
          <div className='col mb-3 px-0'>
            <p className='p-3 w-75 example-div shaded'>Width = 75%<br /><span className='magenta-text'>.w-75</span></p>
          </div>
        </div>
      </div>
    </>
  );
}

export default BootstrapOverview;