import './_main-nav.scss';

function MainNav() {
  return (
    <>
      <div className="offcanvas-lg offcanvas-start" tabindex="-1" id="mobileNav" aria-labelledby="mobileNavLabel">
        <div className="offcanvas-header pb-0">
          <h6 className='invisible'>Mobile Navigation</h6>
          <button type="button" className="btn-close" data-bs-dismiss="offcanvas" data-bs-target="#mobileNav" aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
          <div className='container-fluid'>
            <div className='row d-flex align-items-center mb-2'>
              <div className='col-auto pe-1'>
                <img src="/assets/img/grid-1x2-fill.svg" alt="Layout Icon" className='nav-icon' />
              </div>
              <div className='col ps-1 nav-heading'>
                Layout
              </div>
            </div>
            <div className='row'>
              <div className='col mb-5'>
                <div className='list-group list-group-flush'>
                  <a href="/layout" className='list-group-item list-group-item-action'>Layout Philosophy</a>
                  <a href="/layout/bootstrap" className='list-group-item list-group-item-action'>Bootstrap</a>
                  <a href="/layout/typography" className='list-group-item list-group-item-action'>Typography</a>
                  <a href="/layout/colors" className='list-group-item list-group-item-action'>Colors</a>
                  <a href="/layout/logos" className='list-group-item list-group-item-action'>Logos</a>
                  <a href="/layout/icons" className='list-group-item list-group-item-action'>Icons</a>
                </div>
              </div>
            </div>
            <div className='row d-flex align-items-center mb-2'>
              <div className='col-auto pe-1'>
                <img src="/assets/img/menu-button-wide-fill.svg" alt="Components Icon" className='nav-icon' />
              </div>
              <div className='col ps-1 nav-heading'>
                Components
              </div>
            </div>
            <div className='row'>
              <div className='col mb-5'>
                <div className='list-group list-group-flush'>
                  <a href="/components" className='list-group-item list-group-item-action'>Alerts</a>
                  <a href="/components/form-elements" className='list-group-item list-group-item-action'>Form Elements</a>
                  <a href="/components/buttons" className='list-group-item list-group-item-action'>Buttons</a>
                  <a href="/components/dropdowns" className='list-group-item list-group-item-action'>Dropdowns</a>
                  <a href="/components/toastrs" className='list-group-item list-group-item-action'>Toastrs</a>
                  <a href="/components/tables" className='list-group-item list-group-item-action'>Tables</a>
                  <a href="/components/content-div" className='list-group-item list-group-item-action'>Content Containers</a>
                  <a href="/components/graphs-charts" className='list-group-item list-group-item-action'>Graphs &amp; Charts</a>
                  <a href="/components/modals" className='list-group-item list-group-item-action'>Modals</a>
                  <a href="/components/steps" className='list-group-item list-group-item-action'>Steps</a>
                </div>
              </div>
            </div>
            <div className='row d-flex align-items-center mb-2'>
              <div className='col-auto pe-1'>
                <img src="/assets/img/envelope-fill.svg" alt="Emails Icon" className='nav-icon' />
              </div>
              <div className='col ps-1 nav-heading'>
                Emails
              </div>
            </div>
            <div className='row'>
              <div className='col mb-5'>
                <div className='list-group list-group-flush'>
                  <a href="/emails" className='list-group-item list-group-item-action'>MJML</a>
                  <a href="/emails/boilerplate" className='list-group-item list-group-item-action'>Boilerplate</a>
                  <a href="/emails/white-labeling" className='list-group-item list-group-item-action'>White Labeling</a>
                  <a href="/emails/alert-emails" className='list-group-item list-group-item-action'>Alert Emails</a>
                  <a href="/emails/newsletters" className='list-group-item list-group-item-action'>Newsletters</a>
                  <a href="/emails/customer-emails" className='list-group-item list-group-item-action'>Emails to Customers</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MainNav;