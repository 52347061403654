import './_logos.scss';
import React from 'react';

function Logos() {
  return (
    <>
      <h1>Logos</h1>
      <p className='semibold-text mb-6'>Please note these logos should only be used for parent agents and anyone who is not a sub-agent.</p>
      <h2>Power Kiosk</h2>
      <p>The Power Kiosk logo can be found as PNG, SVG, and EPS files on our company Dropbox, under Marketing. It comes in a variety of orientations and color schemes to fit your needs.</p>
      <h3>Horizontal Logos</h3>
      <div className='container-fluid'>
        <div className='row mb-6'>
          <div className='col-12 col-md-6 col-xl-3 text-center p-4'>
            <img src="/assets/img/pk-logo-horiz-fullColor.png" className='img-fluid' alt="Power Kiosk Full-Color Horizontal Logo" />
          </div>
          <div className='col-12 col-md-6 col-xl-3 text-center p-4 placeholder-gray-bg'>
            <img src="/assets/img/pk-logo-horiz-fullColor-white.png" className='img-fluid' alt="Power Kiosk Full-Color White Horizontal Logo" />
          </div>
          <div className='col-12 col-md-6 col-xl-3 text-center p-4'>
            <img src="/assets/img/pk-logo-horiz-allBlack.png" className='img-fluid' alt="Power Kiosk Black Horizontal Logo" />
          </div>
          <div className='col-12 col-md-6 col-xl-3 text-center p-4 placeholder-gray-bg'>
            <img src="/assets/img/pk-logo-horiz-allWhite.png" className='img-fluid' alt="Power Kiosk White Horizontal Logo" />
          </div>
        </div>
        <h3>Vertical Logos</h3>
        <div className='row mb-6'>
          <div className='col-12 col-md-6 col-xl-3 text-center p-4'>
            <img src="/assets/img/pk-logo-vert-fullColor.png" className='img-fluid' alt="Power Kiosk Full-Color Vertical Logo" />
          </div>
          <div className='col-12 col-md-6 col-xl-3 text-center p-4 placeholder-gray-bg'>
            <img src="/assets/img/pk-logo-vert-fullColor-white.png" className='img-fluid' alt="Power Kiosk Full-Color White Vertical Logo" />
          </div>
          <div className='col-12 col-md-6 col-xl-3 text-center p-4'>
            <img src="/assets/img/pk-logo-vert-allBlack.png" className='img-fluid' alt="Power Kiosk Black Vertical Logo" />
          </div>
          <div className='col-12 col-md-6 col-xl-3 text-center p-4 placeholder-gray-bg'>
            <img src="/assets/img/pk-logo-vert-allWhite.png" className='img-fluid' alt="Power Kiosk White Vertical Logo" />
          </div>
        </div>
      </div>
      <p className='mb-6'>We normally favor using the horizontal logos unless the items in question needs the vertical. The two-color version should be used before the all-white or all-black versions.</p>
      <h2>PowerScore</h2>
      <p>The PowerScore logo does not have any actual logo files.  It is created in any HTML text by adding the <span className='magenta-text'>.powerscore-text</span> to said text, mainly from using <span className='magenta-text'>&lt;span&gt;</span> tags. The class will automatically add a trademark symbol on the end.  You can also make the lockup bigger by using the <span className='magenta-text'>.slightly-bigger</span> class.</p>
      <p className='font-size-2 blue-text mb-6'><span className='powerscore-text slightly-bigger'>PowerScore</span></p>
      <h2>LeadLink</h2>
      <p>The LeadLink Logo applies to our program for brokers to purchase blocks of sales leads.</p>
      <div className='container-fluid'>
        <div className='row mb-6'>
          <div className='col-12 col-md-6 col-xl-3 text-center p-4'>
            <img src="/assets/img/leadlink-logo-fullColor.png" className='img-fluid' alt="LeadLink Full-Color Logo" />
          </div>
          <div className='col-12 col-md-6 col-xl-3 text-center p-4 placeholder-gray-bg'>
            <img src="/assets/img/leadlink-logo-fullColor-white.png" className='img-fluid' alt="LeadLink Full-Color White Logo" />
          </div>
          <div className='col-12 col-md-6 col-xl-3 text-center p-4'>
            <img src="/assets/img/leadlink-logo-allBlack.png" className='img-fluid' alt="LeadLink Black Logo" />
          </div>
          <div className='col-12 col-md-6 col-xl-3 text-center p-4 placeholder-gray-bg'>
            <img src="/assets/img/leadlink-logo-allWhite.png" className='img-fluid' alt="LeadLink White Logo" />
          </div>
        </div>
      </div>
      <h2 className='bold-text'><em>PRICE</em></h2>
      <p><strong><em>PRICE</em></strong> is a program built into the dashboard where our system can analyze and divide up contracts with many locations into smaller contracts based on Load Factor, so they can get the best rates.</p>
      <div className='container-fluid'>
        <div className='row mb-6'>
          <div className='col-12 col-md-6 col-xl-4 text-center p-4'>
            <img src="/assets/img/price-logo-fullColor.png" className='img-fluid' alt="PRICE Full-Color Logo" />
          </div>
          <div className='col-12 col-md-6 col-xl-4 text-center p-4'>
            <img src="/assets/img/price-logo-allBlack.png" className='img-fluid' alt="PRICE Black Logo" />
          </div>
          <div className='col-12 col-md-6 col-xl-4 text-center p-4 placeholder-gray-bg'>
            <img src="/assets/img/price-logo-allWhite.png" className='img-fluid' alt="PRICE White Logo" />
          </div>
        </div>
      </div>
      <p>Please bold and italicize the word “price” in any copy when referring to the program.</p>
    </>
  );
}

export default Logos;