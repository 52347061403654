import './_layout-section.scss';
import {
  Routes,
  Route
} from "react-router-dom";
import BootstrapOverview from './bootstrap-overview/BootstrapOverview';
import Typography from './typography/Typography';
import ColorsOverview from './colors-overview/ColorsOverview';
import Logos from './logos/Logos';
import Icons from './icons/Icons';


function LayoutSection() {
  return (
    <>
      <Routes>
        <Route path="/" element={<LayoutPhilosophy />} />
        <Route path="bootstrap" element={<BootstrapOverview />} />
        <Route path="typography" element={<Typography />} />
        <Route path="colors" element={<ColorsOverview />} />
        <Route path="logos" element={<Logos />} />
        <Route path="icons" element={<Icons />} />
      </Routes>
    </>
  );
}

function LayoutPhilosophy() {
  return (
    <>
      <h1 className='mb-5'>Layout Philosophy</h1>
      <h2>Our Philosophy on Layout</h2>
      <p>Creating any layout for the dashboard is all about placing yourself in the mindset of the end user and thinking about the task at hand from their perspective. We at Power Kiosk keep things down to a simple process by asking four questions:</p>
      <ol className='mb-5'>
        <li>Who is the end user?</li>
        <li>What is the primary reason they are on this page?</li>
        <li>What else do they need from this page?</li>
        <li>What is the desired behavior we want them to perform?</li>
        <li>Are we being consistent?</li>
      </ol>

      <h3>1. Who is the end user?</h3>
      <p>We have four types of end users who are on our dashboard: Parent Brokers, Sub-Agents, Admins, and Suppliers.</p>
      <h4>Parent Agents</h4>
      <p>These are the ones paying the subscription fees and running brokerages with sub-agents as their employees. They are generally male, middle-aged, and somewhat technically adept. They won&rsquo;t be lost using Zoom or making a PDF from a Word Document, but they obviously are not skilled in any kind of deeply complex software, like Photoshop.</p>
      <p>Parent Agents are first and foremost businesspeople and savvy salespeople. They know how to sell and how to hustle, and they want the dashboard to work quickly and accurately to get them the information they need at a moment&rsquo;s notice without having to sift through layers upon layers of complication or configuration.</p>
      <h4>Sub-Agents</h4>
      <p>A Sub-Agent is an employee of a Parent Broker. He/She gets mainly gets access and functionality to get rates perform sales much like a Parent Broker, but a Sub-Agent cannot access company-wide information or controls.</p>
      <p>They are up-and-coming salespeople who are hungry, but obviously not as experienced as the Parent Agents. Sub-Agents can come from both genders, but there is still a more male domination in this industry, and they will be younger, from their 20s to 40s. They are technically adept, even possibly possessing the means or ability to learn complex software, but we should always assume they carry the same technological ability as a Parent Agent, since both types of users will use these same pages and sections.</p>
      <h4>Admins</h4>
      <p>When thinking about our admins, they are generally younger like sub-agents, and very technically adept. Their sections will often contain more information and perhaps some complication, but we should still design on the idea of making information quickly accessible and clear to all levels of employees.</p>
      <h4>Suppliers</h4>
      <p>Those who work on the supply end of our industry will usually be older like a Parent Agent, somewhat technically adept, but they have less time and energy to spend on a dashboard. Usually suppliers have their own systems and software they use for their work, and only come on our specially-made Supplier Dashboard to get information needed on an auction or pricing. You should think about their experience as more a <em>&ldquo;quickly come in, get what they want, and log out&rdquo;</em> ideology.</p>
      <h4>Device Usage</h4>
      <p>This is a question that will often come up with regards to our users. While mobile is king in most areas of the internet, laptops and desktops make up much of what these end users are on when looking at our dashboard. Roughly 75% of our users were on bigger screens.</p>
      <p className='mb-5'>The immense amount of information they require makes it a challenge to see on smaller screens, but we should not dismiss mobile. There could be use cases of brokers needing to pull something up on their phones, or even a few in that 25% who really do all their business on mobile. Every time you make a layout, think about responsive design, and even if there&rsquo;s secondary or tertiary information that could be omitted.</p>

      <h3>2. What is the primary reason they are on this page?</h3>
      <p>Every single page and the dashboard serve one overall purpose for the user. The home page is where the users can manage their contracts, see when renewals are coming, and have a launching point for quotes and setting up customers.  Instant Quote and View Today&rsquo;s Rates serve as the backbone for finding rates and plans to sell. Our reports all serve to bring quick access to necessary information on commission, sales, usage, etc.</p>
      <p className='mb-5'>When tasked with planning and designing any part of the dashboard, have in mind the end user(s), but then think about the one primary reason one is on this page. Even if the request has a list of things to put in, think about the one big reason someone would be here, and focus on that the most.  Make sure you make it as easy as possible to get to what they need for that prime reason, and make everything else secondary or tertiary.</p>

      <h3>3. What else do they need from this page?</h3>
      <p>While we have determined the primary reason for this page or section, there is still information needed to be put in that has to go somewhere.  So they might View Today&rsquo;s Rates to find plans, but they might still need to see other information, how much each plan pays in commission, and other fees involved.</p>
      <p>Every one of these scenarios should be about <strong>taking the user on a journey</strong>. Using the quote page example, their arrival should be about giving them the fastest and easiest path to see rates. Put in a zip code, maybe change the utility or zone, and click submit. Now you have rates.</p>
      <p>When you look at the rate cards, they are divided in such a way to give them their primary needs first and foremost, then secondary items quickly, and tertiary items hidden in a collapsible area.  Usually a broker needs to quickly see the rate, how much their customer can save, and how much commission the broker will get. That&rsquo;s the primary. The secondary are information like billing method, load factor requirement, and green percentage in the gray area.</p>
      <p className='mb-5'>The tertiary information are the items hidden in the collapse such as fees, restrictions, and compensation rules. Brokers generally only need to see this if they are at the point they want to sell this plan to the customer, but not when they&rsquo;re just pricing and comparing. It doesn&rsquo;t need to be openly visible all the time. They can click and take a journey to it.</p>

      <h3>4. What is the desired behavior we want them to perform?</h3>
      <p>These pages and sections we design should be about getting the user to perform an action or behavior. When these users visit a quotes page, we really want them to sign a customer up, which is why those sign-up buttons are so prominent, and why the sign-up process was redesigned into easily-understandable parts so it&rsquo;s not a big massive web form, and they don&rsquo;t miss giving us information we need. All those reports made are about giving the end users quick and accurate information so they won&rsquo;t reach out to our Operations Team with questions. Even on the home page, the Renewal Grid is about pushing the behavior to renew those contracts as opposed to letting them expire.</p>
      <p className='mb-5'>As you are planning and designing any page in our dashboard, always keep in mind what preferred behavior we as a company would like to see come out of this page for the end user. Most of the time, the preferred behavior will be the behavior the end user already had in mind, but sometimes we will design to change a behavior. To push the end user to do something different than what they normally do. Make sure whoever hands you a project to design has determined what desired behavior they want to come out of this page or section.</p>

      <h3>5. Are we being consistent?</h3>
      <p>Last but not least, how consistent is your design in relation to the rest of the dashboard? This doesn&rsquo;t exactly mean you cannot deviate even one pixel beyond what has been done, as you&rsquo;ll see our dashboard is a mixture of several design styles over the years, but is evolving into one style.</p>
      <p>We try our hardest to maintain consistency in our designs though not just for professional reasons, but also because it builds a familiarity to the end user. Whether it&rsquo;s fonts and colors, or which icons you use next to links, or even just the use of div containers versus an open layout, changing things up too much from page to page will only create confusion.</p>
      <p>It&rsquo;s also encouraged to think in terms of components. You want to build a modal that shows a slideshow on select pages? Don&rsquo;t just think about one modal on each of those pages, but if you can make a reusable item that could be put anywhere. That way we&rsquo;re not overbuilding and not making 10 different ways to do the same thing.</p>
      <p>Look at other pages, especially ones similar in flow to what you might design. Ask questions. Don&rsquo;t be afraid to offer up a better way to lay something out, but be prepared to be going back and fixing other similar areas to maintain that consistency.</p>
    </>
  );
}

export default LayoutSection;