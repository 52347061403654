import './_boilerplate.scss';
import React from 'react';
// import { CopyBlock, monoBlue } from "react-code-blocks";

function Boilerplate() {
  return (
    <>
      <div className='row'>
        <div className='col'>
          <h1>Our Email Boilerplate</h1>
        </div>
      </div>
      <div className='row'>
        <div className='col-12 col-xl-7 order-1 order-xl-0'>
          <p>To make your email work easier, we have a <a href="https://static.powerkiosk.com/email/boilerplate/index.html" target="_blank" rel="noreferrer" className='semibold-text'>boilerplate</a> you can download and use to make almost any email.  Simply open in your editor, delete items you do not need, edit the ones you do, and you&rsquo;re all set.</p>
          <p className='text-center font-size-1 my-4'><a href="/assets/pk-email-boilerplate.zip" className='bold-text reverse-deco bigger-icon'><i className='fa-solid fa-file-arrow-down'></i>Download MJML Boilerplate</a></p>
          <p>The boilerplate contains various headers, heroes, content configurations, and footers. Be sure to keep in mind many emails need to be white labeled.</p>
          <p>Also included is a PSD if you wish to make our branded hero image, or reach out to the design team if you need one made. We tend to use free stock photo sites such as <a href="https://unsplash.com/" target="_blank" rel="noreferrer" className='semibold-text'>Unsplash</a>, <a href="https://www.pexels.com/" target="_blank" rel="noreferrer" className='semibold-text'>Pexels</a>, or <a href="https://pixabay.com/" target="_blank" rel="noreferrer" className='semibold-text'>Pixabay</a> for our images.</p>
        </div>
        <div className='col-12 col-xl-5 order-0 order-xl-1 mb-4 mb-xl-0'>
          <a href="https://static.powerkiosk.com/email/boilerplate/index.html" target="_blank" rel="noreferrer" className='semibold-text'><img src="/assets/img/boilerplate-image.jpg" alt="Power Kiosk Email Boilerplate" className='img-fluid shadow' /></a>
        </div>
      </div>
    </>
  );
}

export default Boilerplate;