import './_steps.scss';
import React from 'react';
import { CopyBlock, monoBlue } from "react-code-blocks";

function Steps() {
  return (
    <>
      <h1>Steps</h1>
      <p>Showing numeric steps at the top of a multi-page/multi-view process is a great way to keep the user informed of their status as they run through a process.</p>
      <div className='container-fluid mb-4'>
        <div className="row">
          <div className="col status-bar">
            <ul>
              <li className="passed" style={{ width: "25%" }}>
                Step 1
              </li>
              <li className="active" style={{ width: "25%" }}>
                Step 2
              </li>
              <li style={{ width: "25%" }}>Step 3</li>
              <li style={{ width: "25%" }}>Step 4</li>
            </ul>
          </div>
        </div>
      </div>
      <div className='copy-block-div mb-6'>
        <p className='semibold-text mb-0'>Code</p>
        <CopyBlock
          language="html"
          text={`<div class="row">
    <div class="col status-bar">
        <ul>
            <!-- Widths of the List Items will be calculated by Javascript and put into the inline style. -->
            <!-- Individual widths should be 100% / number of list items -->
            <!-- add active class to active item, passed class to passed items -->
            <li class="active" style="width:25%;">Step 1</li>
            <li style="width:25%;">Step 2</li>
            <li style="width:25%;">Step 3</li>
            <li style="width:25%;">Step 4</li>
        </ul>
    </div>
</div>`}
          theme={monoBlue}
          showLineNumbers={false}
          codeBlock
        />
      </div>
      <p>The numbering on the discs happens automatically using a step process in CSS, but the labels (<em>Step 1, Step 2...</em>) are hard coded. The widths added in as inline-styles are also hard coded. You need to divide 100% by the number of items you have in your steps, and then place that into each list item.</p>
      <p>The normal state for each step is to not show any additional CSS class. Adding <span className='magenta-text'>.active</span> to any list item will make it blue in the "active" state, while <span className='magenta-text'>.passed</span> will show the item in the blue-gray "passed" state.</p>
    </>
  );
}

export default Steps;